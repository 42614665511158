import React, {MouseEvent, useCallback} from "react";
import {useGlobalContext} from "@contexts/Global";
import {Object as OBJ} from "@services/rest/backend/types";
import {produce} from "immer";

type UseHook = {
    open: boolean
    toggleDrawer: (open: boolean) => (event: MouseEvent) => void,
    objects: OBJ[]
    handleDescriptionChange: (id: string) => (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    saveObjects: () => void
}

const useHook = (): UseHook => {
    const [open, setOpen] = React.useState(false)
    const toggleDrawer = useCallback((open: boolean) => (event: MouseEvent) => setOpen(open), [setOpen]);
    const {objects, updateObjects} = useGlobalContext();
    const [localObjects, setLocalObjects] = React.useState<OBJ[]>([])

    React.useEffect(() => {
        setLocalObjects(objects)
    }, [objects]);

    const handleDescriptionChange = useCallback((id: string) =>
        (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setLocalObjects(produce(draft => {
                const obj = draft.find(obj => obj.name === id)
                if (obj) {
                    obj.description = event.target.value
                }
            }))
        }, []);

    const saveObjects = useCallback(() => {
        // save objects
        updateObjects(localObjects)

    }, [localObjects]);

    return {
        open,
        toggleDrawer,
        objects: localObjects,
        handleDescriptionChange,
        saveObjects
    }
}

export{useHook};