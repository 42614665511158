import React from 'react'
import { MessageDirection, User } from '@chatscope/use-chat'
import { useHook } from './useHook'
import { Badge, Card, CardContent } from '@mui/material'
import Message from '@atoms/ChatBox/Message'
import TypingIndicator from '@atoms/TypingIndicator'
import MemoryUsage from "@molecules/MemoryUsage";
type ChatProps = {
    user: User
}


function Chat({ user }: ChatProps) {
  const { messages, getTypingUser, ask, messageBoxRef } = useHook(user)
  return (
    <>
      {messages && messages.length > 0 && (
        <Card ref={messageBoxRef} variant='utterance' sx={{ height: 'calc(80vh - 80px)', overflow: 'scroll' }}>
          <CardContent>
            {messages.map(({ message, direction }, index, msgs) => (
              <Message
                key={message.id}
                side={direction === MessageDirection.Incoming ? 'left' : 'right'}
              >
                {direction === MessageDirection.Incoming ? (
                  <MemoryUsage message={message}/>
                ) : (
                 message.content as any as string
                )}
              </Message>
            ))}
            {getTypingUser() && <TypingIndicator style={{ alignSelf: 'flex-end' }} />}
          </CardContent>
        </Card>
      )}
    </>
  )
}

export default Chat
