import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import SignInForm from '@organisms/SignInForm'
import { ReactComponent as OneupIcon } from '@assets/img/oneup-icon.svg'

function Auth() {
  return (
    <Container component='main' maxWidth='xs'>
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <OneupIcon />
        <Typography component='h1' variant='h5'>Sign in</Typography>
        <SignInForm/>
      </Box>
    </Container>
  )
}

export default Auth
