import { styled } from '@mui/material'

type MessageProps = {
  side?: 'left' | 'right',
}

const defaultProps = {
  side: 'left',
}

const Message = styled('div')<MessageProps>(({ theme, side = 'left' }) => ({
  width: '80%',
  backgroundImage: theme.palette.linearOrange.main,
  backgroundOrigin: 'border-box',
  backgroundClip: 'padding-box, border-box',
  borderRadius: '10px',
  border: 'double 1px transparent',
  padding: theme.spacing(1, 1, 1, 1),
  margin: theme.spacing(1, 1, 1, 1),
  alignSelf: side === 'left' ? 'flex-start' : 'flex-end',
  position: 'relative',
  display: 'inline',
  fontSize: '14px',
  marginLeft: side === 'right' ? 'initial' : ' 35px',
  ':before': {
    content: '""',
    display: side === 'right' ? 'none' : 'inline-block',
    position: 'absolute',
    left: '-35px',
    top: 0,
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%, auto',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'23\' height=\'22\' viewBox=\'0 0 23 22\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M23 9.51221C22.8051 7.14501 21.823 5.03741 20.0521 3.18704C17.8289 1.06366 15.1951 0 12.1522 0C10.1331 0 8.23311 0.464761 6.45305 1.39428C5.2036 1.98766 3.97497 2.96374 2.768 4.32093L2.76633 4.35171H11.6899C13.4742 4.35171 14.9727 4.85908 16.1896 5.86909C17.7773 7.27836 18.5186 8.95908 18.412 10.9104L18.402 11.0943C18.332 12.3915 17.8514 13.6375 16.9635 14.8305C15.3442 16.7125 13.4175 17.6491 11.1852 17.6491C9.7966 17.6491 8.52548 17.2766 7.37015 16.5318C5.42017 15.0815 4.50557 13.2595 4.62635 11.0627L4.63135 10.972C4.65634 10.4978 4.78295 9.80496 5.00952 8.88964L5.6709 6.63212L3.62845 6.18156C2.87128 6.01506 2.2349 6.08529 1.72345 6.3954C1.21118 6.7055 0.84217 7.23891 0.6206 7.99799L0.573121 8.15738C0.255759 9.0514 0.0708399 9.98644 0.0175299 10.9712C-0.146565 13.9531 0.841337 16.5673 2.98374 18.813C5.21776 20.9379 7.85078 22 10.8836 22C14.0131 22 16.806 20.9182 19.2633 18.7514C21.2 16.8963 22.3903 14.8203 22.8367 12.5209C22.8615 12.0333 22.8367 13.9846 23 9.51221Z\' fill=\'url(%23paint0_linear_193_146)\'/%3E%3Cdefs%3E%3ClinearGradient id=\'paint0_linear_193_146\' x1=\'27.5\' y1=\'6.5\' x2=\'0.0650465\' y2=\'11.3667\' gradientUnits=\'userSpaceOnUse\'%3E%3Cstop stop-color=\'%23AC8B16\'/%3E%3Cstop offset=\'1\' stop-color=\'%23E71B0E\'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")',
  },

  '& .Typewriter__wrapper': {
    display: 'inline',
  },

  '& .moneyIn': {
    color: '#80AA60',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700'
  },
  '& .moneyOut': {
    color: '#F98D1B',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '700'
  },
  '& .MuiBadge-root': {
    width: '100%'
  }
}))

export default Message
