import {useHook} from "./usehook";
import {Collapse, Grid} from "@mui/material";
import Questions from "@molecules/Questions";
import React from "react";
import {QuestionsSectionProps} from "@organisms/QuestionsSection/types";

function QuestionsSection({expanded}: QuestionsSectionProps) {
    const {ask, utteranceSections} = useHook()
    return (
        <>
            <Grid item xs={12}>
                <Collapse in={expanded} timeout='auto' unmountOnExit sx={{w: '100%'}}>
                    <Questions handleQuestion={ask} utteranceSections={utteranceSections}/>
                </Collapse>
            </Grid>
        </>
    )
}

export default QuestionsSection