import React, {MouseEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Assistant, Object as OBJ} from "@services/rest/backend/types";
import {produce} from "immer";
import {nanoid} from "nanoid";
import {useGlobalContext} from "@contexts/Global";
import {useAssistantContext} from "@contexts/Assistant";

type UseHook = {
    open: boolean
    toggleDrawer: (open: boolean) => (event: MouseEvent) => void
    assistants: Assistant[]
    allObjects: OBJ[]
    createAssistant: () => void
    saveAssistants: () => void
    handleNameChange: (id: string) => (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    handleDescriptionChange: (id: string) => (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    handlePromptChange: (id: string) => (event: React.FocusEvent<HTMLTextAreaElement>) => void
    handleObjectsChange: (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => void
    handleDeleteAssistant: (id: string) => () => void
    sessionId: string | undefined
    isAdminMode: boolean
}

const useHook = (): UseHook => {
    const {chatInfo, objects} = useGlobalContext();
    const {isAdminMode, updateAssistants, assistants} = useAssistantContext();

    const sessionId = useMemo(() => {
        return isAdminMode ? '1' : chatInfo?.id
    }, [chatInfo?.id, isAdminMode]);

    const [open, setOpen] = React.useState(false)
    const toggleDrawer = useCallback((open: boolean) => (event: MouseEvent) => setOpen(open), [setOpen]);
    const [localAssistants, setLocalAssistants] = useState<Assistant[]>([])
    useEffect(() => {
        setLocalAssistants(assistants)
    }, [assistants]);

    const createAssistant = useCallback(() => {
        setLocalAssistants(produce(draft => {
            draft.push({
                id: nanoid(),
                name: '',
                description: '',
                objects: [],
                tools: [],
                prompt: '',
                user_id: sessionId || ''
            })
        }))
    }, [sessionId]);

    const handleNameChange = useCallback((id: string) =>
        (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setLocalAssistants(produce(draft => {
                const assistant = draft.find(assistant => assistant.id === id)
                if (assistant) {
                    assistant.name = event.target.value
                }
            }))
        }, []);

    const handleDescriptionChange = useCallback((id: string) =>
        (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setLocalAssistants(produce(draft => {
                const assistant = draft.find(assistant => assistant.id === id)
                if (assistant) {
                    assistant.description = event.target.value
                }
            }))
        }, []);

    const handlePromptChange = useCallback((id: string) =>
        (event: React.FocusEvent<HTMLTextAreaElement>) => {
            setLocalAssistants(produce(draft => {
                const assistant = draft.find(assistant => assistant.id === id)
                if (assistant) {
                    assistant.prompt = event.target.value
                }
            }))
        }, []);

    const handleObjectsChange = useCallback((id: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setLocalAssistants(produce(draft => {
                const assistant = draft.find(assistant => assistant.id === id)
                if (assistant) {
                    const isChecked = event.target.checked
                    if (isChecked) {
                        assistant.objects.push(event.target.name)
                    } else {
                        assistant.objects = assistant.objects.filter(obj => obj !== event.target.name)
                    }
                }
            }))
        }, []);

    const handleDeleteAssistant = useCallback((id: string) =>
        () => setLocalAssistants(produce(draft => {
            const index = draft.findIndex(assistant => assistant.id === id)
            if (index !== -1) {
                draft.splice(index, 1)
            }
        })), []);

    const saveAssistants = useCallback(() => {
        updateAssistants(localAssistants);
    }, [localAssistants]);

    return {
        open,
        toggleDrawer,
        assistants: localAssistants,
        allObjects: objects,
        createAssistant,
        saveAssistants,
        handleNameChange,
        handleDescriptionChange,
        handlePromptChange,
        handleObjectsChange,
        handleDeleteAssistant,
        sessionId,
        isAdminMode
    }
}

export {
    useHook
};