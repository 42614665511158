import {
  ChatEvent,
  ChatEventHandler,
  ChatEventType,
  ChatMessage,
  IChatService,
  MessageContentType, MessageDirection,
  MessageEvent, MessageStatus,
  SendMessageServiceParams,
  SendTypingServiceParams,
  UpdateState,
  UserTypingEvent,
} from '@chatscope/use-chat'
import { IStorage } from '@chatscope/use-chat/dist/interfaces'
import {ChatServiceConfig, EventHandlers} from './types'
import Conversation from "@services/conversation/conversatonService";
import {ConversationStateMessage, IContext} from "@services/conversation/stateManager/types";
import {MessagesAndFollowUps} from "@services/rest/backend/types";
import {nanoid} from "nanoid";
import {ResponseType} from "@services/conversation/types";
import {ChatInfo} from "@contexts/Global/types";
import {Observable, ReplaySubject} from "rxjs";
import {map} from "rxjs/operators";

class ChatService implements IChatService {
  storage?: IStorage

  updateState: UpdateState

  conversation: Conversation

  eventHandlers: EventHandlers = {
    onMessage: () => {},
    onConnectionStateChanged: () => {},
    onUserConnected: () => {},
    onUserDisconnected: () => {},
    onUserPresenceChanged: () => {},
    onUserTyping: () => {},
  }

  chatInfo: ChatInfo

  constructor(storage: IStorage, update: UpdateState, config: ChatServiceConfig) {
    this.storage = storage
    this.updateState = update
    this.conversation = new Conversation(config.userInfo.email, config.userInfo.apiKey, {
        onSuccess: this.handleConversationSuccessResponse.bind(this),
        onError: this.handleConversationErrorResponse.bind(this),
        onStateChange: this.handleConversationStateChange.bind(this),
    })
    this.chatInfo = config.chatInfo
  }

  chatProtocolHandler(evt: Event): void {
    const event = evt as CustomEvent
    const {
      detail: { type },
      detail,
    } = event
    if (type === 'message') {
      const message = detail.message as ChatMessage<MessageContentType.TextHtml>
      message.direction = detail.direction
      const { conversationId } = detail
      if (this.eventHandlers.onMessage && detail.sender !== this) {
        this.eventHandlers.onMessage(new MessageEvent({ message, conversationId }))
      }
    } else if (type === 'typing') {
      const { userId, isTyping, conversationId, content, sender } = detail
      if (this.eventHandlers.onUserTyping && sender !== this) {
        this.eventHandlers.onUserTyping(
          new UserTypingEvent({
            userId,
            isTyping,
            conversationId,
            content,
          })
        )
      }
    }
  }

  off<T extends ChatEventType, H extends ChatEvent<T>>(evtType: T, _: ChatEventHandler<T, H>): void {
    const key = `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`
    if (key in this.eventHandlers) {
      this.eventHandlers[key] = () => {}
    }
  }

  on<T extends ChatEventType, H extends ChatEvent<T>>(evtType: T, evtHandler: ChatEventHandler<T, H>): void {
    const key = `on${evtType.charAt(0).toUpperCase()}${evtType.substring(1)}`
    if (key in this.eventHandlers) {
      this.eventHandlers[key] = evtHandler
    }
  }

  sendMessage(params: SendMessageServiceParams): ChatMessage<MessageContentType> {
    const content = params.message.content as any
    const messageId = nanoid()
    this.conversation.sendText(this.chatInfo, content, messageId)
    this.sendTyping({
      conversationId: params.conversationId,
      userId: this.chatInfo.botId,
      content: '',
      isTyping: true,
    })
    return params.message
  }

  sendTyping(params: SendTypingServiceParams): void {
    this.eventHandlers.onUserTyping(
        new UserTypingEvent({
          userId: params.userId,
          isTyping: params.isTyping,
          conversationId: params.conversationId,
          content: params.content,
        })
    )
  }

  private handleConversationSuccessResponse(response: Observable<ResponseType> | undefined,  context: IContext<ConversationStateMessage>) {
    if (response) {
      response.pipe(map((value: ResponseType, index: number) =>
          ({data: value, updateState: index > 0}))).subscribe(({data, updateState}) => {
            const messageId = data.id
            const message = data.payload
              try {
                message.content = JSON.stringify(message.content)
              } catch (e) {
                console.log('error', {e})
                message.content = `${message.content}`
              }

            if (updateState) {
              const currentMessages = this.storage?.getState().currentMessages
              const lastMessage = currentMessages && currentMessages[currentMessages.length - 1]
              const lastMessageContent = lastMessage && lastMessage.messages[lastMessage.messages.length - 1].content
              if (!message.content) {
                message.content = lastMessageContent?.content as string
              } else {
                message.content = (lastMessageContent?.content as string || '') + message.content
              }
              const newMessage = this.buildChatMessage(message, messageId, this.chatInfo.botId)
              this.storage?.updateMessage(newMessage)
              this.updateState()
            } else {
              this.eventHandlers.onMessage(new MessageEvent({
                message: this.buildChatMessage(message, messageId, this.chatInfo.botId),
                conversationId: this.chatInfo.id
              }))
            }
            this.sendTyping({
              conversationId: this.storage?.getState().activeConversation?.id || '',
              userId: this.chatInfo.botId,
              content: '',
              isTyping: true,
            })
      }, (error) => { console.log('error', { error })},
              () => {
              console.log('complete')
              this.sendTyping({
                conversationId: this.storage?.getState().activeConversation?.id || '',
                userId: this.chatInfo.botId,
                content: '',
                isTyping: false,
              })
              this.updateState()
            context.reset()
          }
      )
    }
  }
  private handleConversationErrorResponse(error: any, context: IContext<ConversationStateMessage>) {
    console.log('handleErrorResponse', { error })
    context.reset()
  }
  private handleConversationStateChange(stateMessage: string) {
    if (stateMessage === 'Fetching') {
      this.eventHandlers.onUserTyping(
          new UserTypingEvent({
            userId: this.chatInfo.botId,
            isTyping: true,
            conversationId: this.chatInfo.id,
            content: '',
          })
      )
    }
  }

  private buildChatMessage (message: MessagesAndFollowUps, messageId: string, senderId: string) {
    return new ChatMessage<MessageContentType.Other>({
      id: messageId,
      content: message as any,
      contentType: MessageContentType.Other,
      senderId,
      direction: MessageDirection.Outgoing,
      status: MessageStatus.Sent,
    })
  }

}

export default ChatService
