import {
  ChatMessage,
  Conversation,
  ConversationId,
  ConversationRole,
  MessageContentType,
  Participant,
  Presence,
  TypingUsersList,
  UpdateState,
  User,
  UserStatus,
} from '@chatscope/use-chat'
import {IStorage} from '@chatscope/use-chat/dist/interfaces'
import {useCallback, useMemo} from 'react'
import {nanoid} from 'nanoid'
import ChatService from '@services/chatBox/chatService'
import botAvatar from '@assets/img/assistantIcon.svg'
import {UseChatBot, UseChatBotProps} from "@hooks/useChatBot/types";
import ChatStorageService from "@services/chatBox/ChatStorageService";


const useChatBot = ({userInfo, chatInfo}: UseChatBotProps): UseChatBot => {
  if (userInfo === null || chatInfo === null) {
    throw new Error('userInfo or chatInfo is undefined')
  }


  const serviceFactory = useCallback((storage: IStorage, updateState: UpdateState) => {
    return new ChatService(storage, updateState, {chatInfo, userInfo})
  }, [chatInfo, userInfo])


  const bot = useMemo(() => {
    const botModel = {
      name: chatInfo.botName || '',
      avatar: botAvatar,
    }
    return new User({
      id: chatInfo.botId || '',
      presence: new Presence({ status: UserStatus.Available, description: '' }),
      firstName: '',
      lastName: '',
      username: botModel.name,
      email: '',
      avatar: botModel.avatar,
      bio: '',
    })
  }, [])

  const messageIdGenerator = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (_message: ChatMessage<MessageContentType>) => nanoid(),
      [],
  )
  const groupIdGenerator = useCallback(() => nanoid(), [])
  const conversation = useMemo(() => {
    const createConversation = (id: ConversationId, name: string): Conversation => {
      return new Conversation({
        id,
        participants: [
          new Participant({
            id: name,
            role: new ConversationRole([]),
          }),
        ],
        unreadCounter: 0,
        typingUsers: new TypingUsersList({ items: [] }),
        draft: '',
      })
    }
    return createConversation(chatInfo?.id || nanoid(), bot.id)
  }, [bot.id, chatInfo?.id])

  const storage = useMemo(() => {
    return new ChatStorageService({groupIdGenerator, messageIdGenerator})
  }, [groupIdGenerator, messageIdGenerator])


  return {
    serviceFactory,
    storage,
    bot,
  }
}


export { useChatBot }
