import { IState, IContext, ConversationStateMessage } from '@services/conversation/stateManager/types'
import { FetchingState, InitialState } from '@services/conversation/stateManager'
import { Callbacks, ResponseType } from './types'
import { RestApiService } from '@services/rest/backend/RestApiService'
import { ChatInfo } from '@contexts/Global/types'
import {AskPayload, SOCKET_EVENT} from "@services/rest/backend/types";
import {SocketService} from "@services/rest/backend/SocketService";
import {Observable, ReplaySubject, Subscriber} from "rxjs";

const defaultCallback = () => {}

class Conversation implements IContext<ConversationStateMessage> {
  currentState: IState<ConversationStateMessage>

  protected token: string

  private readonly _onSuccess: (
    data: any | undefined,
    context: IContext<ConversationStateMessage>,
  ) => void

  private readonly _onError: (err: any, context: IContext<ConversationStateMessage>) => void

  private readonly _onStateChange: (
    stateMessage: string,
    context: IContext<ConversationStateMessage>,
  ) => void

  private readonly _restApiService: RestApiService;
  private readonly _socketService: SocketService;

  constructor(
    email: string,
    apiKey: string,
    callbacks: Callbacks<IContext<ConversationStateMessage>, Observable<ResponseType>>,
  ) {
    this.token = Buffer.from(`${email}:${apiKey}`).toString('base64')
    this._onSuccess = callbacks.onSuccess.bind(this) || defaultCallback
    this._onError = callbacks.onError.bind(this) || defaultCallback
    this._onStateChange = callbacks.onStateChange.bind(this) || defaultCallback
    this.currentState = new InitialState(this)
    this._restApiService = new RestApiService()
    this._socketService = new SocketService()
    this.initSocketService()
  }

  private initSocketService() {
    this._socketService.connect()
  }

  private chatResponseSubscriber(messageId: string) {
    return (subscriber: Subscriber<ResponseType>) => {
      this._socketService.chatSocket.on(SOCKET_EVENT.CHAT_RESPONSE, (data: ResponseType) => {
        if (data.id === messageId && data.end) {
          subscriber.complete()
          return
        }
        if (data.id === messageId && !!data.payload) {
          subscriber.next(data)
        }
        console.log('chat response', {data}, {messageId})
      })
    }
  }

  transition(state: IState<ConversationStateMessage>) {
    this.currentState = state
    this._onStateChange(this.currentState.message, this)
  }

  reset() {
    this.transition(new InitialState(this))
  }

  sendText(chatInfo: ChatInfo, message: string | {question: string, thread_id: string}, messageId: string): void {
    this.transition(new FetchingState(this))
    const payload: AskPayload = {
      chatInfo,
      messageId,
      token: this.token,
      message: typeof message === 'string' ? message : message.question,
    }
    if (typeof message !== 'string') {
      payload.thread_id = message.thread_id
    }
    this._socketService.emitChatMessage(payload)
    const response: Observable<ResponseType> = new Observable<ResponseType>(this.chatResponseSubscriber(messageId).bind(this))
    this._onSuccess(response, this)
  }
}

export default Conversation
