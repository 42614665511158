import { IconButton, IconButtonProps, styled } from '@mui/material'
import React from 'react'

type ExpandUtteranceButtonProps = IconButtonProps & {
  expand: boolean;
}

const ExpandUtteranceButton = styled((props: ExpandUtteranceButtonProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandUtteranceButton
