import React from 'react'
import {AutoDraft, ChatProvider} from '@chatscope/use-chat'
import Chat from '@organisms/Chat'
import PageWrapper from '@templates/Container/PageWrapper'
import {Grid} from '@mui/material'
import TextInputChat from '@organisms/TextInputChat'
import {AssistantProvider} from "@contexts/Assistant";
import {ReactComponent as OneupIcon} from '@assets/img/oneup-icon.svg'
import QuestionsSection from "@organisms/QuestionsSection";
import ActionsSection from "@molecules/ActionsSection";
import {useHome} from "./hook";
import Assistants from "@organisms/Assistants";


function Home() {
    const {serviceFactory, storage, handleExpandClick, handleClearButton, user, showQuestions} = useHome()
    return (
        <ChatProvider
            serviceFactory={serviceFactory}
            storage={storage}
            config={{debounceTyping: false, autoDraft: AutoDraft.Save}}>
            <PageWrapper>
                <AssistantProvider isAdmin={false}>
                    <Assistants/>
                </AssistantProvider>
                <Grid item xs={11} display='flex' gap='20px' alignItems='center'>
                    <OneupIcon/>
                    <TextInputChat/>
                </Grid>
                <Grid item xs={1} display='flex' gap='20px' alignItems='center'>
                    <ActionsSection questionsButtonHandler={handleExpandClick}
                                    clearButtonHandler={handleClearButton}/>
                </Grid>
                <QuestionsSection expanded={showQuestions}/>
                <Grid item xs={12}>
                    <Chat user={user}/>
                </Grid>
            </PageWrapper>
        </ChatProvider>
    )
}

export default Home
