import type {AudioStateMessage, IContext, IState} from "./stateManager/types";
import type {Callbacks} from "./types";
import {CanceledAudioState, DoneAudioState, InitialAudioState, RecordingAudioState} from "./stateManager";

const defaultCallback = () => {
}

class AudioControls implements IContext<AudioStateMessage> {
    currentState: IState<AudioStateMessage>

    private readonly _onSuccess: (
        data: Blob | undefined,
        context: IContext<AudioStateMessage>
    ) => void

    private readonly _onError: (err: any, context: IContext<AudioStateMessage>) => void

    private readonly _onStateChange: (
        stateMessage: string,
        context: IContext<AudioStateMessage>
    ) => void

    private _audioCtx: AudioContext | undefined

    private _analyser: AnalyserNode | undefined

    private _mediaRecorder: MediaRecorder | undefined

    private _recordingData: Blob[] = []

    private static MIN_DECIBELS = -65;

    constructor(callbacks: Callbacks<IContext<AudioStateMessage>, Blob>) {
        this._onSuccess = callbacks.onSuccess.bind(this) || defaultCallback
        this._onError = callbacks.onError.bind(this) || defaultCallback
        this._onStateChange = callbacks.onStateChange.bind(this) || defaultCallback
        this.currentState = new InitialAudioState(this)
    }

    get analyzer() {
        return this._analyser
    }

    record() {
        this._audioCtx = new AudioContext()
        this._analyser = this._audioCtx.createAnalyser()
        this._analyser.fftSize = 2048
        this._analyser.minDecibels = AudioControls.MIN_DECIBELS;
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream: MediaStream) => {
            if (!this._audioCtx || !this._analyser) {
                throw new Error('Audio context or analyser not defined')
            }
            // Define the audio context
            // Connect the stream to the audio context
            const source = this._audioCtx.createMediaStreamSource(stream)
            source.connect(this._analyser)
            // Create a new media recorder
            this._mediaRecorder = new MediaRecorder(stream)

            // When the media recorder starts
            this._mediaRecorder.onstart = (): void => {
                // eslint-disable-next-line no-console
                console.log('Started recording')
                this.transition(new RecordingAudioState(this))
            }
            // When the media recorder stops
            this._mediaRecorder.onstop = (): void => {
                // eslint-disable-next-line no-console
                console.log('Stopped recording')
                this.transition(new DoneAudioState(this))
            }

            // When the media recorder collects data
            this._mediaRecorder.ondataavailable = (e: BlobEvent): void => {
                console.log('ondataavailable', e)
                this._recordingData.push(e.data)
            }

            let soundDetected = false;

            const bufferLength = this._analyser.frequencyBinCount;
            const domainData = new Uint8Array(bufferLength);

            const detectSound = () => {
                if (soundDetected) {
                    this._checkSilence()
                } else {
                    this._analyser?.getByteFrequencyData(domainData);

                    for (let i = 0; i < bufferLength; i++) {
                        const value = domainData[i];

                        if (domainData[i] > 0) {
                            soundDetected = true
                        }
                    }
                }
                window.requestAnimationFrame(detectSound.bind(this));
            };

            window.requestAnimationFrame(detectSound.bind(this));


            this._mediaRecorder.start()
        })
    }

    stop(): void {
        if (this._mediaRecorder && this._mediaRecorder.state !== 'inactive') {
            // When the media recorder stops
            this._mediaRecorder.onstop = (): void => {
                // eslint-disable-next-line no-console
                console.log('Stopped recording')
                const data = new Blob(this._recordingData, {type: 'audio/mpeg-3'});
                // const audioUrl = URL.createObjectURL(data);
                // const audio = new Audio(audioUrl);
                // audio.play();
                this._onSuccess(data, this)
                this.transition(new DoneAudioState(this))
            }
            this._mediaRecorder.stop()
        }
    }

    _checkSilence() {
        if (!this._analyser || this._mediaRecorder?.state !== 'recording') {
            return
        }
        let silenceTimeout;

        const audioData = new Uint8Array(this._analyser.frequencyBinCount)
        this._analyser.getByteFrequencyData(audioData)
        const volume = Math.round(audioData.reduce((a, b) => Math.max(a, b)) / 256)

        const isSilent = volume === 0;
        if (isSilent) {
            clearTimeout(silenceTimeout);
            silenceTimeout = setTimeout(() => {
                this.stop();
            }, 5000); // Stop recording after 3 seconds of silence
        } else {
            clearTimeout(silenceTimeout);
        }
    }

    cancel(): void {
        if (this._mediaRecorder && this._mediaRecorder.state !== 'inactive') {
            this._mediaRecorder.stop()
            this.transition(new CanceledAudioState(this))
        }
    }

    reset(): void {
        this.transition(new InitialAudioState(this))
        this._mediaRecorder = undefined
        this._recordingData = []
    }

    transition(state: IState<AudioStateMessage>): void {
        this.currentState = state
        this._onStateChange(this.currentState.message, this)
    }

}

export default AudioControls
