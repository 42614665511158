import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionSummary,
    AppBar,
    Button,
    FormControl,
    Grid,
    IconButton,
    SwipeableDrawer,
    Toolbar
} from "@mui/material";
import React from "react";
import {useHook} from "@organisms/Assistants/useHook";
import ArticleIcon from "@mui/icons-material/Article";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import Assistant from "@molecules/Assistant";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";


function Assistants() {
    const {open, toggleDrawer, assistants, allObjects,
        handleNameChange, handleDescriptionChange, handlePromptChange, createAssistant,
        handleObjectsChange, handleDeleteAssistant, saveAssistants, sessionId, isAdminMode} = useHook()
    return (
        <>
            <Box sx={{height: '30px', position: 'fixed', top: '35%', left: -10, transform: 'rotate(270deg)'}}>
                <Button sx={{h: '100%', w: '100%'}} onClick={toggleDrawer(true)}
                        variant='contained' color='secondary'>Assistant</Button>
            </Box>
            <SwipeableDrawer anchor={"left"} open={open} onClose={toggleDrawer(false)}
                             onOpen={toggleDrawer(true)}>
                <Grid container spacing={2} sx={{pt: 2, width: '70vw'}}>
                    <Box sx={{flexGrow: 1}}>
                        <AppBar position="static" color={'secondary' as any}>
                            <Toolbar>
                                <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}}>
                                    <ArticleIcon/>
                                </IconButton>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Assistants
                                </Typography>
                                <IconButton size="large" edge="start" sx={{mr: 2}} onClick={toggleDrawer(false)}>
                                    <Close/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Grid item xs={12} sx={{m: "30px"}}>
                        <Box>
                            <Button color="secondary" size="large" variant="outlined" startIcon={<AddReactionIcon/>}
                                    onClick={createAssistant}>
                                Add Assistant
                            </Button>
                        </Box>
                        <Box sx={{mb: 5, mt: 5}}>
                            <>
                                {
                                    assistants.filter(value => !isAdminMode && value.user_id === '1').map(assistant => (
                                        <Accordion disabled key={assistant.id}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <FormControl>
                                                    <TextField fullWidth disabled label="name"
                                                               variant="outlined" defaultValue={assistant.name}/>
                                                </FormControl>
                                            </AccordionSummary>
                                        </Accordion>
                                    ))
                                }
                                {
                                assistants.filter(value => isAdminMode || value.user_id === sessionId ).map(assistant => (
                                    <Assistant key={assistant.id} {...assistant} allObjects={allObjects}
                                               handleDescriptionChange={handleDescriptionChange(assistant.id)}
                                               handleNameChange={handleNameChange(assistant.id)}
                                               handlePromptChange={handlePromptChange(assistant.id)}
                                               handleObjectsChange={handleObjectsChange(assistant.id)}
                                               handleDeleteAssistant={handleDeleteAssistant(assistant.id)}
                                    />
                                ))
                            }
                            </>
                        </Box>
                        <Grid item xs={8}>
                            <Button sx={{h: '100%', w: '100%'}} onClick={saveAssistants}
                                    variant='contained' color='secondary'>Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </>
    );
}

export default Assistants;