import {isComparisonOperator, isNumber, isObject, isStatus} from "./validators";
import {objects, statuses, comparisonOperators, logicalOperators} from "./identifiers";
import {CancellationToken, editor, languages, Position} from "monaco-editor";
import CompletionContext = languages.CompletionContext;
import CompletionList = languages.CompletionList;
import ProviderResult = languages.ProviderResult;
import CompletionItemKind = languages.CompletionItemKind;

// Modular Suggestion Functions
const suggestObjects = () => objects.map(obj => ({
    label: obj,
    kind: CompletionItemKind.Keyword,
    insertText: obj
}));

const suggestStatuses = (token: string) => statuses[token].map(status => ({
    label: status,
    kind: CompletionItemKind.TypeParameter,
    insertText: status
}));

const suggestComparisonOperators = () => comparisonOperators.map(op => ({
    label: op,
    kind: CompletionItemKind.Operator,
    insertText: op
}));

const suggestNumbers = () => [{
    label: 'number',
    kind: CompletionItemKind.Text,
    insertText: '0'
}];

const suggestLogicalOperators = () => logicalOperators.map(op => ({
    label: op,
    kind: CompletionItemKind.Operator,
    insertText: op
}));

const getTokens = (model: editor.ITextModel, position: Position) => {
    const textUntilPosition = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column
    });

    return textUntilPosition.trim().split(/\s+/);
};

export const provideCompletionItems =  (model: editor.ITextModel, position: Position, context: CompletionContext, token: CancellationToken): ProviderResult<CompletionList> => {
    const tokens = getTokens(model, position);

    const lastToken = tokens[tokens.length - 1];
    const prevToken = tokens[tokens.length - 2];

    if (isObject(lastToken)) {
        return { suggestions: [...suggestStatuses(lastToken),  ...suggestComparisonOperators()]} as ProviderResult<CompletionList>;
    } else if (isObject(prevToken) && isStatus(lastToken, prevToken)) {
        return { suggestions: suggestComparisonOperators() } as ProviderResult<CompletionList>;
    } else if ((isStatus(prevToken, tokens[tokens.length - 3]) && isComparisonOperator(lastToken)) || isObject(prevToken) && isComparisonOperator(lastToken)) {
        return { suggestions: suggestNumbers() } as ProviderResult<CompletionList>;
    } else if (isNumber(lastToken)) {
        return { suggestions: suggestLogicalOperators() } as ProviderResult<CompletionList>;
    }

    return { suggestions: suggestObjects() } as ProviderResult<CompletionList>;
};