import {FormControl, InputAdornment, OutlinedInput, Stack, Tab, Tabs} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {AdminPromptFilterProps} from "@molecules/AdminPromptFilter/type";

function AdminPromptFilter({tabFilters, handleTabFilterChange,
                               tabFilterValue, handleSearchChange}: AdminPromptFilterProps) {
    return (
        <>
            <Tabs value={tabFilterValue} onChange={handleTabFilterChange}
                  sx={{ minHeight: "48px", display: "flex", paddingLeft: "20px", paddingRight: "20px",
                      boxShadow: "inset 0 -2px 0 0 rgba(var(--palette-grey-500Channel) / 0.08)"}}>
                {tabFilters.map((tabFilter) => (
                    <Tab key={tabFilter.value} value={tabFilter.value} label={tabFilter.label}/>
                ))}
            </Tabs>
            <Stack p="20px" alignItems="center">
                <FormControl fullWidth variant="outlined" onChange={handleSearchChange}>
                    <OutlinedInput placeholder="Search..." startAdornment={
                        <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }/>
                </FormControl>
            </Stack>
        </>
    );
}

export default AdminPromptFilter;