export enum ConversationStateMessage {
  PASSIVE = 'Passive',
  LISTENING = 'Listening',
  FETCHING = 'Fetching',
  TEXTING = 'Texting'
}

export enum AudioStateMessage {
  PASSIVE = 'Passive',
  RECORDING = 'Recording',
  CANCELED = 'Canceled',
  DONE = 'Done',
}

export interface IState<T> {
  readonly _context: IContext<T>
  message: T
  advanceConversation: () => void
}

export interface IContext<T> {
  currentState: IState<T>
  transition: (state: IState<T>) => void
  reset: () => void
}
