import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material'

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    linearBlue: true;
  }
}

type muiTextField = {
  defaultProps?: ComponentsProps['MuiTextField'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiTextField'];
  variants?: ComponentsVariants['MuiTextField'];
};

export const MuiTextField: muiTextField = {
  variants: [
    {
      props: { color: 'linearBlue' },
      style: ({ theme }) => {
        return {
          '& .MuiFormLabel-root': {
            color: '#555555',
            textAlign: 'left',
            font: '300 italic 14px/26px "Open Sans", sans-serif',
            zIndex: 1,
            backgroundColor: (theme as any).palette.background.default,
          },
          '& .MuiInputAdornment-root': {
            zIndex: 1,
          },
          '& .MuiInputBase-input': {
            zIndex: 1,
          },
          '& fieldset': {
            backgroundImage: (theme as any).palette.linearBlue.main,
            backgroundClip: 'padding-box, border-box',
            border: 'double 4px transparent',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        }
      },
    },
  ],
}


