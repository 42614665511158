import {loader} from "@monaco-editor/react";
import {Tanak} from "@config/tanak";
import * as Monaco from "monaco-editor";

type Monaco = typeof Monaco;

// TODO: Implement Monaco Editor

function defineCustomMonacoLanguage({languages}: Monaco) {
    // Register Tanak language
    languages.register({ id: Tanak.id });
    languages.setMonarchTokensProvider(Tanak.id, Tanak.config);
    languages.setLanguageConfiguration(Tanak.id, {
        autoClosingPairs: [
            { open: '(', close: ')' }
        ]
    })
}

function defineCompletion({languages}: Monaco) {
    languages.registerCompletionItemProvider(Tanak.id, Tanak.autocompleteProvider());
}

function defineValidations(monaco: Monaco) {
    monaco.editor.onDidCreateEditor((editor) => {
        editor.onDidChangeModelContent((event) => {
            Tanak.validate(editor.getModel(), monaco);
        })
        Tanak.validate(editor.getModel(), monaco);
    })
}

export function initMonacoEditor() {
    loader.init().then((monaco: Monaco) => {
        defineCustomMonacoLanguage(monaco);
        defineCompletion(monaco);
        defineValidations(monaco);
    });
}