import {Button, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import React from "react";
import type {AdminPromptHeaderProps} from "@molecules/AdminPromptHeader/type";

function AdminPromptHeader({addPromptHandler}: AdminPromptHeaderProps) {
  return (
      <Stack mb="40px" width="100%">
          <Stack display="flex" flexDirection="row" alignItems="center">
              <Box width="80%">
                  <Typography variant="h4" component="h4">Admin Prompts</Typography>
              </Box>
              <Box width="20%" display="flex" justifyContent="flex-end">
                  <Button variant="outlined" startIcon={<EditNoteIcon />} onClick={addPromptHandler}>
                      Add Prompt
                  </Button>
              </Box>
          </Stack>
      </Stack>
  );
}

export default AdminPromptHeader;