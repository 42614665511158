import PageWrapper from "@templates/Container/PageWrapper";
import {AdminProvider} from "@contexts/Admin";
import Assistants from "@organisms/Assistants";
import Objects from "@organisms/Objects";
import AdminPrompts from "@organisms/AdminPrompts";
import {AssistantProvider} from "@contexts/Assistant";

function Admin() {
    return (
        <PageWrapper>
            <AdminProvider>
                <Objects/>
                <AssistantProvider isAdmin={true}>
                    <Assistants/>
                    <AdminPrompts/>
                </AssistantProvider>
            </AdminProvider>
        </PageWrapper>
    )
}

export default Admin