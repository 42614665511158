import Box from "@mui/material/Box";
import {
    AppBar,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    SwipeableDrawer,
    Toolbar
} from "@mui/material";
import React from "react";
import {useHook} from "@organisms/Objects/useHook";
import ArticleIcon from "@mui/icons-material/Article";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import {nanoid} from "nanoid";

function Objects() {
    const {open, toggleDrawer, objects, handleDescriptionChange, saveObjects} = useHook()
    return (
        <>
            <Box sx={{height: '30px', position: 'fixed', top: '50%', left: 0, transform: 'rotate(270deg)'}}>
                <Button sx={{h: '100%', w: '100%'}} onClick={toggleDrawer(true)}
                        variant='contained' color='success'>Object</Button>
            </Box>
            <SwipeableDrawer anchor={"left"} open={open} onClose={toggleDrawer(false)}
                             onOpen={toggleDrawer(true)}>
                <Grid container spacing={2} sx={{pt: 2, width: '70vw'}}>
                    <Box sx={{flexGrow: 1}}>
                        <AppBar position="static" color={'success' as any}>
                            <Toolbar>
                                <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}}>
                                    <ArticleIcon/>
                                </IconButton>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Objects
                                </Typography>
                                <IconButton size="large" edge="start" sx={{mr: 2}} onClick={toggleDrawer(false)}>
                                    <Close/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Grid item xs={12} sx={{m: "30px"}}>
                        <Box  display="flex" flexDirection="column" mb={5} mt={5}>
                            {
                                objects.map(obj => (
                                    <FormControlLabel key={nanoid()} label={`${obj.name} cashflow transaction`} labelPlacement={'top'}
                                                      sx={{mb: 2}}
                                                      control={
                                                          <TextField
                                                              label="Description"
                                                              fullWidth
                                                              multiline
                                                              minRows={4}
                                                              maxRows={10}
                                                              defaultValue={obj.description}
                                                              onBlur={handleDescriptionChange(obj.name)}
                                                          />
                                                      }
                                    />
                                ))
                            }
                        </Box>
                        <Grid item xs={8}>
                            <Button sx={{h: '100%', w: '100%'}} onClick={saveObjects}
                                    variant='contained' color='success'>Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </>
    );
}

export default Objects;