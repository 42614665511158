import {BasicStorage} from "@chatscope/use-chat";

class ChatStorageService extends BasicStorage {
    clearConversations() {
        this.getState().conversations.forEach((conversation) => {
            this.removeConversation(conversation.id);
        });
    }
}

export default ChatStorageService