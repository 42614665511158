import { useGlobalContext } from '@contexts/Global'
import { useCallback, useMemo, useState } from 'react'
import {appRouter} from "@App/routes";


type UseHook = {
  pages: string[]
  handleLogout: () => void
  showMenu: boolean
  sessionId: string | undefined
  anchorElNav: HTMLElement | null
  handleOpenNavMenu: (event: React.MouseEvent<HTMLElement>) => void
  handleCloseNavMenu: () => void
  handlePageChange: (page: string) => void
}

function useHook(): UseHook {
  const pages = ['Home', 'Admin'];
  const { userInfo, chatInfo, logout } = useGlobalContext()
  const sessionId = useMemo(() => {
    return chatInfo?.id
  }, [chatInfo?.id])
  const handleLogout = useCallback(() => {
    logout()
  }, [logout])

  const showMenu = useMemo(() => userInfo !== null, [userInfo])

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  }, [])
  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, [])

  const handlePageChange = useCallback((page: string) => {
    handleCloseNavMenu()
    if (page === 'Home') {
      appRouter.navigate('/')
    }
    if (page === 'Admin') {
      appRouter.navigate('/admin')
    }
  }, [])

  return {
    pages,
    handleLogout,
    showMenu,
    sessionId,
    anchorElNav,
    handleOpenNavMenu,
    handleCloseNavMenu,
    handlePageChange
  }
}

export { useHook }
