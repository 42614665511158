import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
    Checkbox,
    Chip,
    Divider,
    FormControl, FormControlLabel, FormGroup,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import PromptArea from "@atoms/PromptArea";
import Editor from '@monaco-editor/react';
import {AdminPromptProps} from "@molecules/AdminPrompt/type";


function MultiSelectValue({selected, assistants}: {selected: string[], assistants: any[]}) {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (<Chip key={value} label={assistants.find(assistant => assistant.id === value)?.name} />))}
        </Box>
    )
}

function AdminPrompt({assistants, action, assistant_ids, is_suggested, is_followup, conditions, prompt, ...props}: AdminPromptProps) {
    return (
        <Box width="70%" mb={5}>
            <TextField sx={{ mb: 5 }} label="Action" fullWidth value={action || ''} onChange={props.handleActionChange}/>
            <FormControl sx={{ mb: 5 }} fullWidth>
                <InputLabel>Assistants</InputLabel>
                <Select multiple value={assistant_ids || []}
                        onChange={props.handleSelectAssistantsChange} input={<OutlinedInput label="Assistants" />}
                        renderValue={(selected: string[]) => (<MultiSelectValue selected={selected} assistants={assistants}/>)}>
                    {assistants.map((assistant) => (
                        <MenuItem key={assistant.id} value={assistant.id}>{assistant.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box mb={5} display="flex">
                <Box display="flex" sx={{width: "35%"}} alignItems="center">
                    <FormGroup>
                        <FormControlLabel onChange={props.handleIsSuggestedChange}
                                          control={<Checkbox checked={Boolean(is_suggested)} />} label="Is Suggested Prompt" />
                        <FormControlLabel onChange={props.handleIsFollowUpChange}
                                          control={<Checkbox checked={Boolean(is_followup)} />} label="Is Followup Action" />
                    </FormGroup>
                    <Divider orientation="vertical" flexItem sx={{width: "5%"}}/>
                </Box>
                <Box width="60%" ml="10px">
                    <Typography>Conditions</Typography>
                    <Editor height="150px" language="tanak" onChange={(value, _) => props.handleConditionsChange(value)}
                            value={conditions ? conditions.join('\n') : ""}/>
                </Box>
            </Box>
            <FormControl sx={{minWidth: '100%', mb: 5}}>
                <Typography sx={{flexGrow: 1}}>Prompt</Typography>
                <PromptArea language="html"
                            onBlur={props.handlePromptChange} value={prompt}/>
            </FormControl>
        </Box>
    )
}

export default AdminPrompt;