import {
    editor,
    languages,
} from "monaco-editor";
import {objects, statuses, logicalOperators, comparisonOperators} from "@config/tanak/identifiers";
import IMarkerData = editor.IMarkerData;
import ITextModel = editor.ITextModel;
import * as Monaco from "monaco-editor";
import {validateTokenSequence} from "@config/tanak/validators";
import {provideCompletionItems} from "@config/tanak/completions";

type Monaco = typeof Monaco;

type Language = {
    id: string;
    config:  languages.IMonarchLanguage
    autocompleteProvider: () => languages.CompletionItemProvider
    validate: (model: ITextModel | null, monaco: Monaco) => void
}

const languageId = 'tanak';

function autocompleteProvider(): languages.CompletionItemProvider {
    return {
        triggerCharacters: [' '],
        provideCompletionItems: provideCompletionItems
    }
}

function validate(model: ITextModel | null, monaco: Monaco) {
    if (!model) {
        return;
    }
    const text = model.getValue();
    const lines = text.split('\n');
    let errors: IMarkerData[] = [];

    lines.forEach((line, lineIndex) => {
        const tokens = line.trim().split(/\s+/);
        if (tokens.length > 0) {
            errors = errors.concat(validateTokenSequence(tokens, lineIndex, line));
        }
    });

    monaco.editor.setModelMarkers(model, languageId, errors);
}

export const Tanak: Language = {
    id: languageId,
    config: {
        keywords: objects,
        operators: [...logicalOperators, ...comparisonOperators],
        tokenizer: {
            root:[
                [new RegExp(objects.join('|')), 'keyword'],
                [new RegExp(Object.values(statuses).flat().join('|')), 'type'],
                [/\d+/, 'number'],
                [new RegExp(logicalOperators.join('|')), 'operator'],
                [new RegExp(comparisonOperators.join('|')), 'operator'],
                { include: '@whitespace' }
            ],
            whitespace: [
                [/\s+/, 'white']
            ]
        }
    },
    autocompleteProvider,
    validate
}
