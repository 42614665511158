import {styled} from '@mui/material'
import CodeEditor from "@uiw/react-textarea-code-editor";

const PromptArea = styled(CodeEditor)(({theme}) => ({
    resize: 'vertical',
    width: '100%',
    minHeight: '200px',
    'textarea': {
        resize: "vertical"
    }
}))

export default PromptArea
