import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react'
import {AssistantContextData, AssistantContextProps} from '@contexts/Assistant/types'
import {Assistant} from '@services/rest/backend/types'
import { RestApiService } from '@services/rest/backend'
import { useGlobalContext } from '@contexts/Global'
import { toast } from 'react-toastify';


const AssistantContext = createContext<AssistantContextData>({} as AssistantContextData)

function useAssistantProvider({isAdmin}: AssistantContextProps): AssistantContextData {
  const { chatInfo } = useGlobalContext()

  const [assistants, setAssistants] = useState<Assistant[]>([])


  const restApiService = useMemo(() => new RestApiService(), [])


  const getAssistant = (user_ids: string[]) => {
    restApiService.getAssistant(user_ids).then((assistants) => {
        setAssistants(assistants)
    })
  }


  useEffect(() => {
    if (!chatInfo) return
    const user_ids = isAdmin ? ['1'] : ['1', chatInfo.id]
    getAssistant(user_ids)
  }, [restApiService, isAdmin, chatInfo])


  const updateAssistants = (data: Assistant[]) => {
    if (!chatInfo) return
    const userId = isAdmin ? '1' :  chatInfo.id
    restApiService.updateAssistant(userId, data)
        .then(() => setAssistants(data))
        .then(() => toast.success('Assistants updated'))
        .catch(() => toast.error('Error updating assistants'))
  }

  return {
    updateAssistants,
    assistants,
    isAdminMode: isAdmin
  }
}

function AssistantProvider({ children, isAdmin }: PropsWithChildren<AssistantContextProps>) {
  const values = useAssistantProvider({isAdmin})

  return <AssistantContext.Provider value={values}>{children}</AssistantContext.Provider>
}

const useAssistantContext = (): AssistantContextData => {
  const assistantContext = useContext<AssistantContextData>(AssistantContext)
  if (assistantContext === undefined) {
    throw new Error('AssistantContext must be used within a Provider')
  }
  return assistantContext
}

export { AssistantProvider, useAssistantContext, AssistantContext }
