import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

const onSuccessResponse = (response: AxiosResponse) => response

const onErrorResponse = (err: AxiosError) => {
  const status = (err && err.status) || (err && err.response ? err.response.status : 0)
  if (status === 500) {
    // TODO: Handle Errors console.error('Erreur Interne, Réessayez plus tard')
  }
  if (status === 401) {
    // TODO: Handle Errors console.error('Erreur Interne, Réessayez plus tard')
  }
  return Promise.reject(err.response)
}

export const forThisUrl = (apiUrl: string): { getAxiosInstance: () => AxiosInstance } => ({
  getAxiosInstance: (): AxiosInstance => {
    const client = axios.create({
      baseURL: apiUrl,
    })
    client.interceptors.response.use(onSuccessResponse, onErrorResponse)
    return client
  },
})
