import { SxProps } from '@mui/system'

const sxPropCSS: SxProps = {
  '& .MuiFormLabel-root': {
    padding: '0 15px'
  },
  '& fieldset': {
    borderRadius: '30px',
  }
}

export default sxPropCSS
