import {useGlobalContext} from "@contexts/Global";
import {useChatBot} from "@hooks/useChatBot";
import React, {useCallback, useEffect, useMemo, useReducer} from "react";
import {RestApiService} from "@services/rest/backend";
import {toast} from "react-toastify";
import {
    Conversation,
    ConversationId,
    ConversationRole,
    Participant,
    Presence,
    TypingUsersList,
    User, UserStatus
} from "@chatscope/use-chat";

type HomeHook = {
    serviceFactory: any
    storage: any
    user: any
    handleExpandClick: () => void
    handleClearButton: () => void
    showQuestions: boolean
}

function useHome(): HomeHook {
    const {userInfo, chatInfo} = useGlobalContext()
    const {bot, serviceFactory, storage} = useChatBot({userInfo, chatInfo})
    const [showQuestions, setShowQuestions] = React.useState(false)
    const restApiService = useMemo(() => new RestApiService(), [])
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const user = useMemo(() => {
        if (userInfo === null) {
            throw new Error('userInfo is undefined')
        }
        return new User({
            id: userInfo.email,
            presence: new Presence({ status: UserStatus.Available, description: '' }),
            firstName: userInfo.name,
            lastName: '',
            username: userInfo.name,
            email: userInfo.email,
            avatar: userInfo.avatar,
            bio: '',
            data: {
                apiKey: userInfo.apiKey,
            },
        })
    }, [userInfo])

    const createConversation = useCallback((id: ConversationId, name: string): Conversation => {
        return new Conversation({
            id,
            participants: [
                new Participant({
                    id: name,
                    role: new ConversationRole([]),
                }),
            ],
            unreadCounter: 0,
            typingUsers: new TypingUsersList({items: []}),
            draft: '',
        })
    }, [])

    const initConversation = useCallback(() => {
        const conversation = createConversation(chatInfo?.id || '', bot.id)
        storage.addUser(bot)
        storage.addConversation(conversation)
        storage.setActiveConversation(conversation.id)
    }, [storage, chatInfo?.id, bot.id]);

    const handleExpandClick = useCallback(() => {
        setShowQuestions(prevState => !prevState)
    }, [showQuestions])

    const handleClearButton = useCallback(() => {
        if (!chatInfo?.id) return
        restApiService.clearChat(chatInfo.id).then(() => {
            storage.clearConversations()
            initConversation()
            forceUpdate()
            toast.success('Chat cleared')
        }).catch(() => {
            toast.error('Error clearing chat')
        })
    }, [chatInfo?.id, storage])

    useEffect(() => {
        initConversation()
    }, [storage, chatInfo?.id, bot.id]);


    return {
        serviceFactory,
        storage,
        user,
        handleExpandClick,
        handleClearButton,
        showQuestions,
    }
}

export {useHome}