import {toast} from "react-toastify";

export const copyTextToClipboard = (key: string, text: string) => {
    const toClipboard = async () => {
        if (navigator.clipboard) {
            return await navigator.clipboard.writeText(text)
        } else {
            return document.execCommand('copy', true, text)
        }
    }
    toClipboard()
        .then(() => toast.success(`${key} Copied to clipboard`))
        .catch(() => toast.error(`Error copying ${key} to clipboard`))

}