import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton
} from "@mui/material";
import {nanoid} from "nanoid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import PromptArea from "@atoms/PromptArea";
import {AssistantProps} from "@molecules/Assistant/types";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";

function Assistant({id, name, description, objects, prompt, allObjects, ...props}: AssistantProps) {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const toggleExpanded = () => setExpanded(prevState => !prevState);
    const [expandedObjects, setExpandedObjects] = React.useState<boolean>(false);
    const toggleExpandedObjects = () => setExpandedObjects(prevState => !prevState);
    return (
        <Accordion key={nanoid()} expanded={expanded} onChange={toggleExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <FormControl>
                    <TextField fullWidth
                               label="name"
                               variant="outlined" defaultValue={name}
                               onBlur={event => {
                                   event.preventDefault()
                                   if (props.handleNameChange) {
                                       props.handleNameChange(event)
                                   }
                                   event.stopPropagation()
                               }}
                               onClick={event => event.stopPropagation()}
                    />
                </FormControl>

                <IconButton size="large" sx={{marginLeft: 'auto', marginRight: '10px'}}
                            color="error"
                            onClick={event => {
                                event.preventDefault()
                                if (props.handleDeleteAssistant) {
                                    props.handleDeleteAssistant()
                                }
                                event.stopPropagation()
                            }
                            }
                            data-id-message={id}>
                    <DeleteIcon fontSize="inherit"/>
                </IconButton>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl sx={{width: '100%',  mb: 5}}>
                    <TextField
                        label="Description"
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={10}
                        defaultValue={description}
                        onBlur={event => {
                            event.preventDefault()
                            if (props.handleDescriptionChange) {
                                props.handleDescriptionChange(event)
                            }
                            event.stopPropagation()
                            }
                        }
                    />
                </FormControl>
                <FormControl sx={{minWidth: '100%', mb: 5}}>
                    <Accordion
                        expanded={expandedObjects}
                        onChange={toggleExpandedObjects}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Objects (click to show objects)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                allObjects.map(({name}) =>
                                    <FormControlLabel key={nanoid()} label={`${name} cashflow transaction`}
                                                      control={
                                                          <Checkbox
                                                              onChange={event => {
                                                                  event.preventDefault()
                                                                  if (props.handleObjectsChange) {
                                                                      props.handleObjectsChange(event)
                                                                  }
                                                                  event.stopPropagation()
                                                              }}
                                                              name={name}
                                                              checked={objects.includes(name)}
                                                          />
                                                      }
                                    />)
                            }
                        </AccordionDetails>
                    </Accordion>
                </FormControl>
                <FormControl sx={{minWidth: '100%', mb: 5}}>
                    <Typography sx={{flexGrow: 1}}>Prompt</Typography>
                    <PromptArea data-id-message={id}
                                language="html"
                                onBlur={props.handlePromptChange} value={prompt}/>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    );
}

export default Assistant;