import { ChatInfo } from '@contexts/Global/types'
import BackendApi from './api'
import {AdminPrompt, Assistant, Object as OBJ} from '@services/rest/backend/types'
import { AxiosInstance } from 'axios'

type User = { logo: string, company_name: string, locale: string }

export class RestApiService {
  private _api = BackendApi
  async newChat(chatId: string): Promise<ChatInfo> {
    const { data } = await this.api.post('/chat/new', { chat_id: chatId })
    return data
  }

    async sessionExists(chatId: string): Promise<boolean> {
      const { status } = await this.api.get('/chat/session', { params: { chat_id: chatId } })
          .catch((reason) => {
            if (reason.status === 404) {
              return { status: 404 }
            }
            throw reason
          })
        return status === 204 || status === 200
    }

  clearChat(chatId: string): Promise<void> {
    return  this.api.delete(`/chat/session`, { params: { chat_id: chatId } })
  }

  async getAssistant(user_ids: string[]): Promise<Assistant[]> {
    const { data } = await this.api.get('/admin/assistant', { params: { user_ids: user_ids.join(',') } })
    return data
  }

  updateAssistant(user_id: string , data: Assistant[]): Promise<void> {
    return this.api.put('/admin/assistant', { user_id, assistants: data })
  }

  async getObject(): Promise<OBJ[]> {
    const { data } = await this.api.get('/admin/object')
    return data
  }

  updateObject(data: OBJ[]): Promise<void> {
    return this.api.put('/admin/object', {objects: data})
  }

    async getAdminPrompts(): Promise<AdminPrompt[]> {
        const { data } = await this.api.get('/admin/admin_prompt')
        return data
    }

  saveAdminPrompt(data: AdminPrompt): Promise<void> {
    return this.api.put('/admin/admin_prompt', data)
  }

  deleteAdminPrompt(id: string): Promise<void> {
    return this.api.delete('/admin/admin_prompt', { params: { id } })
  }

  async me(email: string, apiKey: string): Promise<User> {
    const token = Buffer.from(`${email}:${apiKey}`).toString('base64')
    const headers = {Authorization: `Basic ${token}`}
    const {data} = await this.api.get<User>('/me', {headers})
    return data
  }

  private get api(): AxiosInstance {
    return this._api
  }
}
