import React from 'react'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {defaultTheme} from '@themes/default'
import {GlobalProvider} from '@contexts/Global'
import {RouterProvider} from 'react-router-dom'
import {appRouter} from './routes'
import Box from "@mui/material/Box";
import Menu from "@organisms/Menu";
import 'react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "react-toastify";
function App() {

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <Box sx={{flexGrow: 1}}>
                <GlobalProvider>
                    <Menu/>
                    <RouterProvider router={appRouter}/>
                </GlobalProvider>
            </Box>
            <ToastContainer />
        </ThemeProvider>
    )
}

export default App
