import React, { PropsWithChildren } from 'react'
import { Navigate, createHashRouter } from 'react-router-dom'
import Home from '@pages/Home/Page'
import Auth from '@pages/Auth/Page'
import Admin from '@pages/Admin/Page'
import { useGlobalContext } from '@contexts/Global'

function ProtectedRoute({ children }: PropsWithChildren): React.ReactElement {
  const { userInfo } = useGlobalContext()
  return <>{userInfo ? children : <Navigate to='/auth' />}</>
}

const appRouter = createHashRouter([
  {
    path: '/',
    element: <ProtectedRoute children={<Home />} />,
  },
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/admin',
    element: <ProtectedRoute children={<Admin />} />,
  }
])

export { appRouter }
