import { useState } from 'react'

type UseSessionStorage<T> = [
  storedValue: T,
  setValue: (value: T) => void
]

function useSessionStorage<T>(keyName: string, defaultValue: T): UseSessionStorage<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.sessionStorage.getItem(keyName)
      if (value) {
        return JSON.parse(value)
      } else {
        window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })
  const setValue = (newValue: T) => {
    try {
      window.sessionStorage.setItem(keyName, JSON.stringify(newValue))
    } catch (err) {
    }
    setStoredValue(newValue)
  }
  return [storedValue, setValue]
}

export {
  useSessionStorage,
}
