import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react'
import { AdminContextData } from '@contexts/Admin/types'
import {AdminPrompt} from '@services/rest/backend/types'
import { RestApiService } from '@services/rest/backend'
import { toast } from 'react-toastify';


const AdminContext = createContext<AdminContextData>({} as AdminContextData)

function useAdminProvider(): AdminContextData {

  const [adminPrompts, setAdminPrompts] = useState<AdminPrompt[]>([])

  const restApiService = useMemo(() => new RestApiService(), [])

  const getAdminPrompts = () => {
    restApiService.getAdminPrompts().then((adminPrompts) => {
      setAdminPrompts(adminPrompts)
    })
  }

  useEffect(() => {
    getAdminPrompts()
  }, [restApiService])



  const saveAdminPrompt = (data: AdminPrompt) => {
    restApiService.saveAdminPrompt(data)
        .then(() => toast.success('Admin prompt updated'))
        .then(() => getAdminPrompts())
        .catch(() => toast.error('Error updating admin prompt'))
  }

  const deleteAdminPrompt = (id: string) => {
    restApiService.deleteAdminPrompt(id)
        .then(() => toast.success('Admin prompt deleted'))
        .then(() => getAdminPrompts())
        .catch(() => toast.error('Error deleting admin prompt'))
  }

  return {
    saveAdminPrompt,
    deleteAdminPrompt,
    adminPrompts,
  }
}

function AdminProvider({ children }: PropsWithChildren<{}>) {
  const values = useAdminProvider()

  return <AdminContext.Provider value={values}>{children}</AdminContext.Provider>
}

const useAdminContext = (): AdminContextData => {
  const adminContext = useContext<AdminContextData>(AdminContext)
  if (adminContext === undefined) {
    throw new Error('AdminContext must be used within a Provider')
  }
  return adminContext
}

export { AdminProvider, useAdminContext, AdminContext }
