import * as React from 'react'
import {useCallback, useState} from 'react'
import { useGlobalContext } from '@contexts/Global'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router-dom'
import { RestApiService } from '@services/rest/backend/RestApiService'

type UseHook = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  hasError: boolean
  hasSessionError: boolean
}

function useHook(): UseHook {
  const { login, newChat } = useGlobalContext()
  const navigate = useNavigate()
  const [hasError, setHasError] = useState<boolean>(false)
  const [hasSessionError, setHasSessionError] = useState<boolean>(false)
  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setHasError(false)
    setHasSessionError(false)
    const data = new FormData(event.currentTarget)
    const email = data.get('email')?.toString()
    const apiKey = data.get('apiKey')?.toString()
    const sessionId = data.get('sessionId')?.toString()
    if (email === undefined || apiKey === undefined) {
      return
    }
    try {
      const restApiService = new RestApiService()
      const user =  await restApiService.me(email, apiKey)
      if (sessionId && sessionId.length > 0) {
        const exists = await restApiService.sessionExists(sessionId)
        if (!exists) {
          setHasSessionError(true)
         return
        }
      }
      const chat = await restApiService.newChat(sessionId || nanoid())
      login({
        email,
        apiKey,
        avatar: user.logo,
        name: user.company_name,
      })
      newChat(chat)
      setTimeout(() => {
        navigate('/', { replace: true })
      })
    } catch (err) {
      console.error({err})
        setHasError(true)
    }
  }, [])
  return { handleSubmit, hasError, hasSessionError }
}

export { useHook }
