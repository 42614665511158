import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import * as React from 'react'
import { useHook } from '@organisms/SignInForm/useHook'
import { Alert } from '@mui/material'

function SignInForm() {
  const { handleSubmit, hasError, hasSessionError } = useHook()
  return (
    <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin='normal'
        required
        fullWidth
        label='Email Address'
        name='email'
        autoFocus />
      <TextField
        margin='normal'
        required
        fullWidth
        name='apiKey'
        label='API Key'
        type='password' />
        <TextField
        margin='normal'
        fullWidth
        label='Session ID'
        name='sessionId'
        autoFocus />
      <Alert severity="error" sx={{display: hasSessionError ? 'block' : 'none'}}>Session ID invalid &/or not Found</Alert>
      <Alert severity="error" sx={{display: hasError ? 'block' : 'none'}}>Email Address &/or API Key invalid</Alert>
      <Button
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
    </Box>
  )
}

export default SignInForm
