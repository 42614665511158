import { ChatInfo } from '@contexts/Global/types'

export type User = { logo: string; company_name: string; locale: string }

export type HighMemoryUsage =
  | { [key: string]: { used: boolean; content: string; response?: string } }
  | undefined

export type TimeLogger = {
  name: string
  level: number
  parent: string
  time: number
}

export type AskPayload = {
  chatInfo: ChatInfo
  message: string
  token: string
  thread_id?: string
  messageId?: string
}

export type MessagesAndFollowUps = {
  content: string
}

export type MessageMetadata = {
  high_memory_provider: string
  high_memory_usage: HighMemoryUsage
  anonymized_entities: string[]
  time_logger: TimeLogger[]
  token_logger: number
  thread_id: string
}

export enum SOCKET_EVENT {
  TRANSCRIPT = 'transcript',
  VOICE = 'voice',
  CHAT_MESSAGE = 'chat_message',
  CHAT_RESPONSE = 'chat_response',
}


export type Assistant = {
  id: string
  name: string
  description: string
  tools: string[]
  objects: string[]
  prompt: string
  user_id: string
}

export type Object = {
  name: string
  description: string
}

export type AdminPrompt = {
  id: string
  action: string
  assistant_ids: string[]
  is_suggested: boolean
  is_followup: boolean
  conditions: string[]
  prompt: string
}