import {AppBar, Button, Grid, IconButton, Paper, SwipeableDrawer, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import Close from "@mui/icons-material/Close";
import AdminPromptHeader from "@molecules/AdminPromptHeader";
import AdminPromptFilter from "@molecules/AdminPromptFilter";
import AdminPromptList from "@molecules/AdminPromptList";
import {useHook} from "./useHook";
import AdminPrompt from "@molecules/AdminPrompt";
import React from "react";
import {actions} from "@storybook/addon-actions";

function AdminPrompts() {
    const {assistantFilter, handleTabFilterChange, adminPromptsRows, open,
        toggleDrawer, tabFilters, dataLoading,
        adminPrompt,
        assistants,
        handleActionChange,
        handleSelectAssistantsChange,
        handleIsSuggestedChange,
        handleIsFollowUpChange,
        handleConditionsChange,
        handlePromptChange,
        handleSaveAdminPrompt,
        handleEditPrompt,
        handleDeletePrompt,
        handleSearchChange
    } = useHook()
    const  adminPromptProps = {
        assistants,
        handleActionChange,
        handleSelectAssistantsChange,
        handleIsSuggestedChange,
        handleIsFollowUpChange,
        handlePromptChange,
        handleConditionsChange
    }

    return (
        <>
            <AdminPromptHeader addPromptHandler={toggleDrawer(true)}/>
            <Paper sx={{ position: "relative", borderRadius: "16px", overflow: "hidden", width: "100%",
                boxShadow:"rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms" }}>
                <AdminPromptFilter tabFilterValue={assistantFilter}
                                   handleTabFilterChange={handleTabFilterChange} tabFilters={tabFilters}
                                   handleSearchChange={handleSearchChange}/>
                <AdminPromptList loading={dataLoading} adminPrompts={adminPromptsRows} handleEditPrompt={handleEditPrompt} handleDeletePrompt={handleDeletePrompt}
                />
            </Paper>
            <SwipeableDrawer anchor={"left"} open={open} onClose={toggleDrawer(false)}
                             onOpen={toggleDrawer(true)}>
                <Grid container spacing={2} sx={{pt: 2, width: '70vw'}} flexDirection="row">
                    <Box width="100%" mb={5} sx={{flexGrow: 1}}>
                        <AppBar position="static" color={'primary' as any}>
                            <Toolbar>
                                <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}}>
                                    <ArticleIcon/>
                                </IconButton>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Prompt
                                </Typography>
                                <IconButton size="large" edge="start" sx={{mr: 2}} onClick={toggleDrawer(false)}>
                                    <Close/>
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Box width="100%"  justifyContent="end" display="flex" pr={5}>
                        <Button variant='contained' onClick={handleSaveAdminPrompt}>Save</Button>
                    </Box>
                    <Grid item xs={12} m="30px" justifyContent="center" display="flex">
                        <AdminPrompt {...adminPrompt} {...adminPromptProps}/>
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </>
  );
}

export default AdminPrompts;