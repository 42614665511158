import { Manager } from 'socket.io-client';

export const socketManager = new Manager(window.location.origin, {
    autoConnect: false,
    withCredentials: true,
    extraHeaders: {
        'HTTP_ORIGIN': window.location.origin
    }
});

export const  forThisNamespace = (namespace: string) => {
    return {
        getSocket: () => socketManager.socket('/' + namespace),
    }
}
