import React from "react";
import {ChatMessage, MessageContentType} from "@chatscope/use-chat";
import {HighMemoryUsage, MessagesAndFollowUps} from "@services/rest/backend/types";
import {Badge} from "@mui/material";
import Typewriter from "@atoms/Typewriter";
import MarkdownPreview from "@uiw/react-markdown-preview";

type MemoryUsageProps = {
    message: ChatMessage<MessageContentType>

}
function MemoryUsage({message}: MemoryUsageProps) {

    const getMemoryUsage = (memory: HighMemoryUsage) => {
        if (memory === undefined) {
            return 0
        }
        return Object.entries(memory).reduce((acc, [key, value]) => {
            return acc + (value.used ? 1 : 0)
        }, 0)
    }


    return (
        <Badge badgeContent={0 + '/2'} color={"info"}>
            {
                (typeof message.content === 'string') ? (
                    <Typewriter>
                        <MarkdownPreview source={message.content}/>
                    </Typewriter>
                ): (
                    <Typewriter>
                        <MarkdownPreview
                            warpperElement={{'data-color-mode': 'light'}}
                            style={{'width': '100%'}}
                            source={message.content.content as string}/>
                    </Typewriter>
                )
            }
        </Badge>
    )
}

export default MemoryUsage