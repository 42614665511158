import React from "react";
import {IconButton} from "@mui/material";
import {QuizOutlined} from "@mui/icons-material";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import {ActionsSectionProps} from "@molecules/ActionsSection/types";

function ActionsSection({clearButtonHandler, questionsButtonHandler}: ActionsSectionProps) {
    return (
        <>
            <IconButton onClick={questionsButtonHandler}>
                <QuizOutlined color={'primary'} fontSize={'large'}/>
            </IconButton>
            <IconButton onClick={clearButtonHandler}>
                <CleaningServicesOutlinedIcon color={'primary'} fontSize={'large'}/>
            </IconButton>
        </>
    )
}

export default ActionsSection