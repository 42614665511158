import {
  ConversationStateMessage,
  IState,
  IContext, AudioStateMessage,
} from '@services/conversation/stateManager/types'

export class TextingState implements IState<ConversationStateMessage> {
  readonly _context: IContext<ConversationStateMessage>

  private readonly _message: ConversationStateMessage

  constructor(context: IContext<ConversationStateMessage>) {
    this._context = context
    this._message = ConversationStateMessage.TEXTING
  }

  advanceConversation(): void {
    this._context.reset()
  }

  get message(): ConversationStateMessage {
    return this._message
  }
}

export class FetchingState implements IState<ConversationStateMessage> {
  readonly _context: IContext<ConversationStateMessage>

  private readonly _message: ConversationStateMessage

  constructor(context: IContext<ConversationStateMessage>) {
    this._context = context
    this._message = ConversationStateMessage.FETCHING
  }

  advanceConversation(): void {
    this._context.transition(new TextingState(this._context))
  }

  get message(): ConversationStateMessage {
    return this._message
  }
}
export class ListeningState implements IState<ConversationStateMessage> {
  readonly _context: IContext<ConversationStateMessage>

  private readonly _message: ConversationStateMessage

  constructor(context: IContext<ConversationStateMessage>) {
    this._context = context
    this._message = ConversationStateMessage.LISTENING
  }

  advanceConversation(): void {
    this._context.transition(new FetchingState(this._context))
  }

  get message(): ConversationStateMessage {
    return this._message
  }
}
export class InitialState implements IState<ConversationStateMessage> {
  readonly _context: IContext<ConversationStateMessage>

  private readonly _message: ConversationStateMessage

  constructor(context: IContext<ConversationStateMessage>) {
    this._context = context
    this._message = ConversationStateMessage.PASSIVE
  }

  advanceConversation(): void {
    this._context.transition(new ListeningState(this._context))
  }

  get message(): ConversationStateMessage {
    return this._message
  }
}

export class DoneAudioState implements IState<AudioStateMessage> {
  readonly _context: IContext<AudioStateMessage>

  private readonly _message: AudioStateMessage

  constructor(context: IContext<AudioStateMessage>) {
    this._context = context
    this._message = AudioStateMessage.DONE
  }

  advanceConversation(): void {
    this._context.reset()
  }

  get message(): AudioStateMessage {
    return this._message
  }
}

export class CanceledAudioState implements IState<AudioStateMessage> {
  readonly _context: IContext<AudioStateMessage>

  private readonly _message: AudioStateMessage

  constructor(context: IContext<AudioStateMessage>) {
    this._context = context
    this._message = AudioStateMessage.DONE
  }

  advanceConversation(): void {
    this._context.reset()
  }

  get message(): AudioStateMessage {
    return this._message
  }
}

export class RecordingAudioState implements IState<AudioStateMessage> {
  readonly _context: IContext<AudioStateMessage>

  private readonly _message: AudioStateMessage

  constructor(context: IContext<AudioStateMessage>) {
    this._context = context
    this._message = AudioStateMessage.RECORDING
  }

  advanceConversation(): void {
    this._context.transition(new DoneAudioState(this._context))
  }

  get message(): AudioStateMessage {
    return this._message
  }
}

export class InitialAudioState implements IState<AudioStateMessage> {
  readonly _context: IContext<AudioStateMessage>

  private readonly _message: AudioStateMessage

  constructor(context: IContext<AudioStateMessage>) {
    this._context = context
    this._message = AudioStateMessage.PASSIVE
  }

  advanceConversation(): void {
    this._context.transition(new RecordingAudioState(this._context))
  }

  get message(): AudioStateMessage {
    return this._message
  }
}
