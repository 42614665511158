// Validation Functions
import {comparisonOperators, logicalOperators, statuses, objects} from "./identifiers";
import {MarkerSeverity} from "monaco-editor";

// Enum for validation states
const ValidationState = Object.freeze({
    EXPECT_OBJECT: 'EXPECT_OBJECT',
    EXPECT_OPTIONAL_STATUS_OR_OPERATOR: 'EXPECT_OPTIONAL_STATUS_OR_OPERATOR',
    EXPECT_OPERATOR: 'EXPECT_OPERATOR',
    EXPECT_NUMBER: 'EXPECT_NUMBER',
    EXPECT_LOGICAL_OPERATOR_OR_END: 'EXPECT_LOGICAL_OPERATOR_OR_END'
});

export const isObject = (token: string) => objects.includes(token);

export const isStatus = (token: string, prevToken: string) => statuses[prevToken]?.includes(token);

export const isComparisonOperator = (token: string) => comparisonOperators.includes(token);

export const isLogicalOperator = (token: string) => logicalOperators.includes(token);

export const isNumber = (token: string) => /^\d+$/.test(token);

const createError = (message: string, lineIndex: number, startColumn: number, endColumn: number) => {
    return {
        severity: MarkerSeverity.Error,
        message: message,
        startLineNumber: lineIndex + 1,
        startColumn: startColumn + 1,
        endLineNumber: lineIndex + 1,
        endColumn: endColumn + 1
    };
};

const createWarning = (message: string, lineIndex: number, startColumn: number, endColumn: number) => {
    return {
        severity: MarkerSeverity.Warning,
        message: message,
        startLineNumber: lineIndex + 1,
        startColumn: startColumn + 1,
        endLineNumber: lineIndex + 1,
        endColumn: endColumn + 1
    };
};

export const validateTokenSequence = (tokens: string[], lineIndex: number, line: string) => {
    let state: string = ValidationState.EXPECT_OBJECT;
    let object: string = '';
    const errors = [];

    let currentIndex = 0;

    tokens.forEach((token, tokenIndex) => {
        let startIndex = line.indexOf(token, currentIndex);
        let endIndex = startIndex + token.length;
        currentIndex = endIndex;

        switch (state) {
            case ValidationState.EXPECT_OBJECT:
                if (isObject(token)) {
                    object = token;
                    state = ValidationState.EXPECT_OPTIONAL_STATUS_OR_OPERATOR;
                } else {
                    errors.push(createError(`Expected an object but found "${token}"`, lineIndex, startIndex, endIndex));
                }
                break;

            case ValidationState.EXPECT_OPTIONAL_STATUS_OR_OPERATOR:
                if (isStatus(token, object)) {
                    state = ValidationState.EXPECT_OPERATOR;
                } else if (isComparisonOperator(token)) {
                    state = ValidationState.EXPECT_NUMBER;
                } else if (isLogicalOperator(token) && object && statuses[object].length === 0) {
                    state = ValidationState.EXPECT_OBJECT;
                } else if (isLogicalOperator(token) && tokenIndex === tokens.length - 1) {
                    errors.push(createWarning(`Unexpected end of line after "${token}"`, lineIndex, startIndex, endIndex));
                } else {
                    errors.push(createError(`Expected a status, comparison operator, or logical operator after "${object}" but found "${token}"`, lineIndex, startIndex, endIndex));
                }
                break;

            case ValidationState.EXPECT_OPERATOR:
                if (isComparisonOperator(token)) {
                    state = ValidationState.EXPECT_NUMBER;
                } else {
                    errors.push(createError(`Expected a comparison operator after status "${tokens[tokenIndex - 1]}" but found "${token}"`, lineIndex, startIndex, endIndex));
                }
                break;

            case ValidationState.EXPECT_NUMBER:
                if (isNumber(token)) {
                    state = ValidationState.EXPECT_LOGICAL_OPERATOR_OR_END;
                } else {
                    errors.push(createError(`Expected a number after comparison operator "${tokens[tokenIndex - 1]}" but found "${token}"`, lineIndex, startIndex, endIndex));
                }
                break;

            case ValidationState.EXPECT_LOGICAL_OPERATOR_OR_END:
                if (isLogicalOperator(token)) {
                    state = ValidationState.EXPECT_OBJECT;
                } else {
                    errors.push(createWarning(`Expected a logical operator ("and", "or") or end of line after "${tokens[tokenIndex - 1]}" but found "${token}"`, lineIndex, startIndex, endIndex));
                }
                break;

            default:
                errors.push(createError(`Unexpected state at token "${token}"`, lineIndex, startIndex, endIndex));
        }
    })
    if (state !== ValidationState.EXPECT_LOGICAL_OPERATOR_OR_END && state !== ValidationState.EXPECT_OBJECT) {
        errors.push(createError(`Incomplete expression at the end of line`, lineIndex, lineIndex, line.length));
    }

    return errors;
};