import { createTheme, ThemeOptions } from '@mui/material'
import { components } from './components'
import { foundations } from './foundations'

const themeOptions: Omit<ThemeOptions, "components"> = {
  ...foundations,
};
export const defaultTheme = createTheme({
  ...themeOptions,
  components
})


