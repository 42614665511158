import {AppBar, Button, Container, Divider, IconButton, MenuItem, Toolbar} from "@mui/material";
import React, {Fragment} from "react";
import {useHook} from "@organisms/Menu/useHook";
import Typography from "@mui/material/Typography";
import {copyTextToClipboard} from "@utils/index";
import './menu.scss'
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';

function AppMenu() {
    const {pages, handleLogout, showMenu, sessionId,
        handleCloseNavMenu, handleOpenNavMenu, anchorElNav, handlePageChange} = useHook()
    if (!showMenu) return <></>
    return (
        <AppBar position="static" sx={{flexDirection: "row"}}>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, maxWidth: "fit-content" }}>
                    <IconButton size="large" color="inherit" onClick={handleOpenNavMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Menu anchorEl={anchorElNav} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} keepMounted
                          anchorOrigin={{ vertical: 'bottom',  horizontal: 'left' }}
                          transformOrigin={{ vertical: 'top',  horizontal: 'left' }}
                          sx={{ display: { xs: 'block', md: 'none' } }}>
                        {pages.map((page) => (
                            <MenuItem key={page} onClick={() => handlePageChange(page)}>
                                <Typography textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <Toolbar sx={{justifyContent: 'flex-start', flex: 1,  maxWidth: "fit-content"}}>
                    <Typography className='session_id' onClick={()=> copyTextToClipboard('sessionId', sessionId || '')}>{sessionId}</Typography>
                </Toolbar>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, padding: "0px 20px" }}>
                    {pages.map((page, index) => (
                        <Fragment key={page}>
                            <Button onClick={() => handlePageChange(page)} color="inherit" variant="contained"
                                    sx={{
                                        my: 2, color: 'black', display: 'block',
                                        textTransform: 'capitalize', boxShadow: 'none'}}>
                                {page}
                            </Button>
                            {index !== pages.length - 1 &&
                                <Divider variant="middle" orientation="vertical" flexItem
                                         sx={{ borderColor: "white", mr: 2, ml: 2}} />
                            }
                        </Fragment>
                    ))}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Toolbar sx={{justifyContent: 'flex-end'}}>
                <Button onClick={handleLogout} color="inherit">Sign out</Button>
            </Toolbar>
        </AppBar>
    )
}

export default AppMenu
