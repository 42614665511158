import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from '@mui/material'

declare module '@mui/material/Paper' {
  export interface PaperPropsVariantOverrides {
    utterance: true;
  }
}

type muiCard = {
  defaultProps?: ComponentsProps['MuiCard'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiCard'];
  variants?: ComponentsVariants['MuiCard'];
};

export const MuiCard: muiCard = {
  variants: [
    {
      props: { variant: 'utterance' },
      style: {
        background: '#ffffff',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: '#e0e0e0',
        borderWidth: '1px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        '& .MuiCardHeader-root': {
          background: '#fafafa',
          borderStyle: 'solid',
          borderColor: '#e0e0e0',
          borderWidth: '0px 0px 1px 0px',
          textAlign: 'center',
          alignSelf: 'stretch',
          '& .MuiTypography-root': {
            font: '600 italic 16px/18px "Open Sans", sans-serif',
            textTransform: 'capitalize',
          },
        },
        '& .MuiCardContent-root': {
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '14px',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          alignSelf: 'stretch',
          flexShrink: '0',
          position: 'relative',
        },
        '& .MuiCardActions-root': {
          padding: '10px 0px 0px 0px',
          flexShrink: '0',
        },
        '& .MuiCollapse-root': {
          alignSelf: 'stretch',
        },
      },
    },
  ],
}
