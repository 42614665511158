import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  ChatMessage,
  MessageContent,
  MessageContentType,
  MessageDirection,
  MessageStatus,
  TextContent,
  useChat,
  User,
} from '@chatscope/use-chat'
import { MessagesAndFollowUps } from '@services/rest/backend/types'
import { useGlobalContext } from '@contexts/Global'
import {OtherContent} from "@chatscope/use-chat/src/interfaces/MessageContent";
import {nanoid} from "nanoid";

const useHook = (user: User) => {
  // Get all chat related values and methods from useChat hook
  const {
    currentMessages,
    activeConversation,
    sendMessage,
    getUser,
    setCurrentMessage,
    sendTyping,
    setCurrentUser,
  } = useChat()
  const messageBoxRef = useRef<HTMLDivElement>(null)

  const { userInfo } = useGlobalContext()

  const scrollToBottom = useCallback(() => {
    const element = messageBoxRef.current
    if (element === null) {
        return
    }
    setTimeout(() => {
      element.scroll({ top: element.scrollHeight, behavior: 'smooth' })
    }, 100)

  }, [messageBoxRef])


  useEffect(() => {
    setCurrentUser(user)
  }, [user, setCurrentUser])

  const handleChange = (value: string) => {
    // Send typing indicator to the active conversation
    // You can call this method on each onChange event
    // because sendTyping method can throttle sending this event
    // So typing event will not be send to often to the server
    setCurrentMessage(value)
    if (activeConversation) {
      sendTyping({
        conversationId: activeConversation?.id,
        isTyping: true,
        userId: user.id,
        content: value, // Note! Most often you don't want to send what the user types, as this can violate his privacy!
        throttle: true,
      })
    }
  }

  const getIncomingAndLastMessage = (messages:  {message: ChatMessage<MessageContentType>, direction: MessageDirection}[] | undefined): MessagesAndFollowUps | undefined  => {
    if (!messages || messages.length === 0) {
      return undefined
    }
    const lastMessage = messages[messages.length - 1]
    if (lastMessage.direction === MessageDirection.Outgoing) {
      return undefined
    }
    return lastMessage.message.content as any as MessagesAndFollowUps
  }

  const getTypingUser = () => {
    if (activeConversation) {
      const { typingUsers } = activeConversation
      const participantsTyping = typingUsers.length > 0 && typingUsers.items.filter((typingUser) => {
        return activeConversation.participantExists(typingUser.userId)
      })

      if (participantsTyping && participantsTyping.length > 0) {
        return participantsTyping[0].isTyping
      }
    }
    return undefined
  }

  const messages = useMemo(() => {
    const getDirection = (message: ChatMessage<MessageContentType>) => {
      return message.senderId === user.id ? MessageDirection.Outgoing : MessageDirection.Incoming
    }
    scrollToBottom()
    return (
      activeConversation &&
      currentMessages?.map(({ messages }) => messages)
        .flatMap((message) => message)
        .map((message) => {
          return {
            ...message,
            content: 'question' in message.content ? (message.content as any).question : message.content,
          }
        })
        .map((message) => ({ message, direction: getDirection(message) }))
    )
  }, [JSON.stringify(currentMessages), activeConversation, user.id])


  const ask = useCallback((question: string) => {
      if (userInfo) {
        const message = new ChatMessage({
          id: '', // Id will be generated by storage generator, so here you can pass an empty string
          content: {
            question,
            thread_id: nanoid(),
          } as unknown as MessageContent<OtherContent>,
          contentType: MessageContentType.TextHtml,
          senderId: userInfo.email,
          direction: MessageDirection.Outgoing,
          status: MessageStatus.Sent,
        })
        if (activeConversation) {
          sendMessage({
            message,
            conversationId: activeConversation.id,
            senderId: userInfo.email,
          })
        }
      }
    },
    [userInfo],
  )

  return {
    ask,
    messages,
    handleChange,
    messageBoxRef,
    getTypingUser
  }
}

export { useHook }
