import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useAdminContext} from "@contexts/Admin";
import {AdminPrompt, Assistant} from "@services/rest/backend/types";
import {SelectChangeEvent} from "@mui/material";
import {produce} from "immer";
import {useConfirm} from "material-ui-confirm";
import {useAssistantContext} from "@contexts/Assistant";

function adminPromptToRow(prompt: AdminPrompt, assistants: Assistant[]): any {
    return {
        id: prompt.id,
        action: prompt.action,
        conditions: prompt.conditions,
        assistants: prompt.assistant_ids.map(id => assistants.find(assistant => assistant.id === id)?.name),
        isFollowUp: prompt.is_followup,
        isSuggested: prompt.is_suggested
    }
}

function useHook() {
    const {adminPrompts, saveAdminPrompt, deleteAdminPrompt} = useAdminContext();
    const {assistants} = useAssistantContext()

    const [dataLoading, setDataLoading] = useState(true)

    const [assistantFilter, setAssistantFilter] = React.useState('all');
    const [adminPromptsRows, setAdminPromptsRows] = useState<any[]>([])
    const handleTabFilterChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setAssistantFilter(newValue);
        const rows = adminPrompts.filter(({assistant_ids}) => newValue === 'all' || assistant_ids.includes(newValue))
            .map((prompt) => (adminPromptToRow(prompt, assistants)))
        setAdminPromptsRows(rows)
    }, [adminPrompts, assistants])

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value
        const rows = adminPrompts.filter(({action}) => action.includes(search))
            .filter(({assistant_ids}) => assistantFilter === 'all' || assistant_ids.includes(assistantFilter))
            .map((prompt) => (adminPromptToRow(prompt, assistants)))
        setAdminPromptsRows(rows)
    }, [adminPrompts, assistants])


   useEffect(() => {
        setDataLoading(false)
        setAdminPromptsRows(adminPrompts.map((prompt) => (adminPromptToRow(prompt, assistants))
        ))
    }, [adminPrompts, assistants])

    const [adminPrompt, setAdminPrompt] = useState<AdminPrompt>({} as AdminPrompt)

    const tabFilters = useMemo(() => {
        const filters = assistants?.map(assistant => ({ value: assistant.id, label: assistant.name})) || []
        return [{ value: 'all', label: 'All' }, ...filters]
    }, [assistants]);

    const [open, setOpen] = React.useState(false)
    const toggleDrawer = useCallback((open: boolean) => () => {
        setOpen(open)
        if (!open) setAdminPrompt({} as AdminPrompt)
    }, [setOpen]);

    const handleActionChange = useCallback(({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setAdminPrompt(produce(draft => {
            draft.action = value
        }))
    }, [])


    const handleSelectAssistantsChange = useCallback(({target: {value}}: SelectChangeEvent<string[]>) => {
        const selected =  typeof value === 'string' ? value.split(',') : value
        setAdminPrompt(produce(draft => {
            draft.assistant_ids = selected
        }))
    }, [assistants])

    const handleIsSuggestedChange = useCallback((event: React.SyntheticEvent, checked: boolean) => {
        setAdminPrompt(produce(draft => {
            draft.is_suggested = checked
        }))
    }, [])

    const handleIsFollowUpChange = useCallback((event: React.SyntheticEvent, checked: boolean) => {
        setAdminPrompt(produce(draft => {
            draft.is_followup = checked
        }))
    }, [])

    const handleConditionsChange = useCallback((value: string | undefined) => {
        if (!value) return
        const conditions = value.split('\n')
        setAdminPrompt(produce(draft => {
            draft.conditions = conditions
        }))
    }, [])

    const handlePromptChange = useCallback(({target: {value}}: React.FocusEvent<HTMLTextAreaElement>) => {
        setAdminPrompt(produce(draft => {
            draft.prompt = value
        }))
    }, [])

    const handleSaveAdminPrompt = useCallback(() => {
        setDataLoading(true)
        saveAdminPrompt(adminPrompt)
        setOpen(false)
        setAdminPrompt({} as AdminPrompt)
    }, [adminPrompt])

    const handleEditPrompt = useCallback((data: any) => {
        const prompt = adminPrompts.find(({id}) => id === data.id)
        setAdminPrompt(prompt as AdminPrompt)
        setOpen(true)
    }, [adminPrompts])

    const confirm = useConfirm();


    const handleDeletePrompt = useCallback((data: any) => {
        confirm({description: 'Do you really want to delete this prompt?'})
            .then(() => deleteAdminPrompt(data.id))
    }, [adminPrompts, confirm])

    return {
        assistantFilter,
        handleTabFilterChange,
        adminPromptsRows,
        open,
        dataLoading,
        toggleDrawer,
        tabFilters,
        assistants,
        adminPrompt,
        handleActionChange,
        handleSelectAssistantsChange,
        handleIsSuggestedChange,
        handleIsFollowUpChange,
        handleConditionsChange,
        handlePromptChange,
        handleSaveAdminPrompt,
        handleEditPrompt,
        handleDeletePrompt,
        handleSearchChange
    }
}

export {
    useHook
}