import React from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { ArrowCircleUp, Mic, Stop } from '@mui/icons-material'
import { useHook } from '@organisms/TextInputChat/useHook'
import sxPropCSS from '@organisms/TextInputChat/styles'

function IconsButtons({isRecording, startRecord, stopRecord}: {isRecording: boolean, startRecord: () => void, stopRecord: () => void}) {
  return (
    <InputAdornment position='end'>
        {
            isRecording ? (
                <IconButton onClick={stopRecord}>
                    <Stop />
                </IconButton>
            ) : (
                <IconButton onClick={startRecord}>
                    <Mic />
                </IconButton>
            )
        }
      <IconButton>
        <ArrowCircleUp />
      </IconButton>
    </InputAdornment>
  )

}

function TextInputChat() {
  const {handleSend, handleChange, currentMessage,
      isRecording,
      audioControl, canvasRef} = useHook();
  return (
    <>
      <TextField fullWidth
                 sx={sxPropCSS}
                 value={currentMessage}
                 onChange={(event) => handleChange(event.target.value)}
                 onKeyDown={(event) => {
                   if (event.key === 'Enter') {
                     event.preventDefault()
                     handleSend()
                   }
                 }}
                 label='Ask anything to your business assistant...'
                 InputProps={{
                     startAdornment: isRecording && (
                         <InputAdornment position='start' >
                         <canvas
                             ref={canvasRef}
                             style={{
                                 width: '90%',
                                 height: '90%',
                                 padding: '10px',
                                 position: 'absolute',
                                 bottom: '-4px',
                                 borderRadius: '30px',
                             }}
                         />
                         </InputAdornment>
                     ),
                     endAdornment: (<IconsButtons
                         isRecording={isRecording}
                         startRecord={() => audioControl.record()}
                         stopRecord={() => audioControl.stop()}
                     />),
                 }}
                 variant='outlined'
                 color={'linearBlue'} />
    </>
  )
}

export default TextInputChat
