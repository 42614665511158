import {DataGrid, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React from "react";
import {AdminPromptListProps} from "@molecules/AdminPromptList/type";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

function AdminPromptList({loading, adminPrompts, handleEditPrompt, handleDeletePrompt}: AdminPromptListProps) {
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', align: 'center' , headerAlign: 'center'},
        { field: 'action', headerName: 'Action', display: 'flex', width: 300},
        { field: 'conditions', headerName: 'Condition', display: 'flex', width: 250},
        { field: 'assistants', headerName: 'Assistants', display: 'flex', width: 200},
        { field: 'isSuggested', headerName: 'A Suggested', type: 'boolean', width: 100},
        { field: 'isFollowUp', headerName: 'A Follow Up', type: 'boolean', width: 150},
        { field: 'actions',  type: 'actions', width: 30,  align: 'right', getActions: (params: GridRowParams) => [
            <GridActionsCellItem icon={<DeleteIcon/>} label="Delete" showInMenu
                                     onClick={() => handleDeletePrompt && handleDeletePrompt(params.row)} />,
            <GridActionsCellItem icon={<EditIcon/>} label="Edit" showInMenu
                                     onClick={() => handleEditPrompt && handleEditPrompt(params.row)} />,
            ]
        }
    ];
    return (
        <Box>
            <DataGrid loading={loading} rows={adminPrompts} columns={columns} pageSizeOptions={[5, 10]} disableRowSelectionOnClick={true}
                      initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }} autoHeight />
        </Box>
    );
}

export default AdminPromptList;