import {useGlobalContext} from "@contexts/Global";
import {
    ChatMessage,
    MessageContent,
    MessageContentType,
    MessageDirection, MessageStatus,
    TextContent,
    useChat
} from "@chatscope/use-chat";
import {useCallback, useMemo} from "react";
import {UtteranceSection} from "@molecules/Questions/types";

type UseHook = {
    utteranceSections: UtteranceSection[]
    ask: (question: string) => void
}
export const useHook = (): UseHook => {
    const { userInfo } = useGlobalContext()
    if (userInfo === null) {
        throw new Error('userInfo is undefined')
    }

    const {sendMessage, activeConversation} = useChat()


    const ask = useCallback((question: string) => {
        const message = new ChatMessage({
            id: '', // Id will be generated by storage generator, so here you can pass an empty string
            content: question as unknown as MessageContent<TextContent>,
            contentType: MessageContentType.TextHtml,
            senderId: userInfo.email,
            direction: MessageDirection.Outgoing,
            status: MessageStatus.Sent,
        })
        if (activeConversation) {
            sendMessage({
                message,
                conversationId: activeConversation.id,
                senderId: userInfo.email,
            })
        }
    }, [])

    const utteranceSections = useMemo<UtteranceSection[]>(() => {
        const moneyIn: UtteranceSection = {
            name: 'ask about your money in',
            color: 'linearBlue',
            utterances: {
                principal: [
                    'What\'s the list of outstanding invoices that I need to collect?',
                    'What customer payments do I need to collect during this month?',
                    'Which draft invoices do I need to turn into final invoices?',
                ],
                hidden: [
                    'Can you help me find out which invoices are overdue and need to be collected?',
                    'Which invoices are awaiting payment from customers this month?',
                    'What invoices in draft status require finalization?',
                ],
            },
        }
        const moneyOut: UtteranceSection = {
            name: 'ask about your money out',
            color: 'linearYellow',
            utterances: {
                principal: [
                    'How much do I anticipate spending this month?',
                    'What is the projected cost for the month\'s expenses?',
                    'What is the most recent payment made towards expenses this month?',
                ],
                hidden: [
                    'What is my expected total expense for the remainder of the month?',
                    'What is the anticipated expenditure for this month?',
                    'What is the latest payment made towards this month\'s expenses?',
                ],
            },
        }
        const sale: UtteranceSection = {
            name: 'ask about your sales',
            color: 'linearPurple',
            utterances: {
                principal: [
                    'What estimates have expired or are reaching their validity end?',
                    'What estimates are open?',
                    'What is the forecasted amount of my sales for the month?',
                    'What is the procedure for creating an estimate?',
                ],
                hidden: [
                    'What open estimates need to be finalized?',
                    'What are the sales forecasts for this month?',
                    'Could you explain how to create an estimate?',
                ],
            },
        }
        return [moneyIn, moneyOut, sale]
    }, [])


    return { ask, utteranceSections }
}