import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    ChatMessage,
    MessageContent,
    MessageContentType,
    MessageDirection,
    MessageStatus,
    useChat,
} from '@chatscope/use-chat'
import {useGlobalContext} from '@contexts/Global'
import {OtherContent} from "@chatscope/use-chat/src/interfaces/MessageContent";
import AudioControls from "@services/conversation/audioControls";
import {AudioStateMessage} from "@services/conversation/stateManager/types";
import {SocketService} from "@services/rest/backend/SocketService";
import {SOCKET_EVENT} from "@services/rest/backend/types";
import {nanoid} from "nanoid";

type UseHook = {
    currentMessage: string
    handleChange: (message: string) => void
    handleSend: () => void
    audioControl: AudioControls
    canvasRef: React.RefObject<HTMLCanvasElement>
    isRecording: boolean
}

function useHook(): UseHook {

    const {userInfo} = useGlobalContext()

    if (userInfo === null) {
        throw new Error('userInfo is undefined')
    }

    const {
        activeConversation,
        sendMessage,
        currentMessage,
        setCurrentMessage,
        sendTyping,
    } = useChat()
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [audioState, setAudioState] = useState<AudioStateMessage | null>(null)
    const socketService = useMemo(() => new SocketService(), [])

    useEffect(() => {
        socketService.connect()
        socketService.chatSocket.on(SOCKET_EVENT.TRANSCRIPT, (data: string) => {
            handleChange(data)
        })
        return () => {
            socketService.disconnect()
        }
    }, []);


    const audioControl = useMemo(() => new AudioControls({
        onSuccess: (data, context) => {
            if (data === null || data === undefined) {
                return
            }
            socketService.emitVoice(data)
            context.reset()
        },
        onError: (err) => {},
        onStateChange: (state) => {
            setAudioState(state as AudioStateMessage)
        },
    }), [socketService])

    useEffect(() => {
        if (canvasRef.current === null || audioControl === undefined) {
            return
        }

        if (audioState === AudioStateMessage.RECORDING) {

            const visualizer = canvasRef.current
            const drawVisualizer = () => {
                // Connecting the analyzer to the media source
                requestAnimationFrame(drawVisualizer)
                if (audioControl.analyzer === null || audioControl.analyzer === undefined) {
                    return
                }
                const dataArray = new Uint8Array(audioControl.analyzer.frequencyBinCount)

                // Updating the analyzer with the new
                // generated data visualization
                audioControl.analyzer.getByteFrequencyData(dataArray)
                const {width} = visualizer
                const {height} = visualizer
                const barWidth = 6
                const canvasContext = visualizer.getContext('2d')
                if (canvasContext === null) {
                    return
                }
                canvasContext.clearRect(0, 0, width, height)
                let x = 0
                dataArray.forEach((item) => {
                    // This formula decides the height of the vertical
                    // lines for every item in dataArray
                    const y = (item / 100) * height * 1.1
                    canvasContext.strokeStyle = `#c6f3ec`
                    // This decides the distances between the
                    // vertical lines
                    x += barWidth
                    canvasContext.beginPath()
                    canvasContext.lineCap = 'round'
                    canvasContext.lineWidth = 8
                    canvasContext.moveTo(x, height)
                    canvasContext.lineTo(x, height - y)
                    canvasContext.stroke()
                })
            }
            drawVisualizer()
        }

    }, [audioState])

    const handleChange = useCallback((value: string) => {
        // Send typing indicator to the active conversation
        // You can call this method on each onChange event
        // because sendTyping method can throttle sending this event
        // So typing event will not be send to often to the server
        setCurrentMessage(value)
        if (activeConversation) {
            sendTyping({
                conversationId: activeConversation?.id,
                isTyping: true,
                userId: userInfo.email,
                content: value, // Note! Most often you don't want to send what the user types, as this can violate his privacy!
                throttle: true,
            })
        }
    }, [activeConversation, sendTyping, userInfo.email, setCurrentMessage])


    const handleSend = useCallback(() => {
            const message = new ChatMessage({
                id: '', // Id will be generated by storage generator, so here you can pass an empty string
                content: {
                    question: currentMessage,
                    thread_id: nanoid(),
                } as unknown as MessageContent<OtherContent>,
                contentType: MessageContentType.TextHtml,
                senderId: userInfo.email,
                direction: MessageDirection.Outgoing,
                status: MessageStatus.Sent,
            })
            if (activeConversation) {
                sendMessage({
                    message,
                    conversationId: activeConversation.id,
                    senderId: userInfo.email,
                })
            }
        }
        , [currentMessage, sendMessage, activeConversation])

    return {
        handleSend,
        handleChange,
        currentMessage,
        audioControl,
        canvasRef,
        isRecording: audioState === AudioStateMessage.RECORDING,
    }
}

export {useHook}
