import React, { PropsWithChildren } from 'react'
import { Container, Grid } from '@mui/material'
import { ReactComponent as OneupLogo } from '@assets/img/oneup-logo.svg'

function PageWrapper({ children }: PropsWithChildren) {

  return (
    <Container maxWidth='lg' sx={{ pt: '30px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} alignItems='center' display='flex' justifyContent='center'>
          {/*<OneupLogo />*/}
        </Grid>
        {children}
      </Grid>
    </Container>
  )
}

export default PageWrapper
