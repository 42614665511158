import {forThisNamespace} from "@config/socket.config";
import {Socket} from "socket.io-client";
import {AskPayload, SOCKET_EVENT} from "@services/rest/backend/types";


export class SocketService {
    private readonly _chatSocket: Socket

    constructor() {
        this._chatSocket = forThisNamespace('chat').getSocket()
    }

    get chatSocket(): Socket {
        return this._chatSocket
    }

    connect(): void {
        if (this._chatSocket.connected) return
        this._chatSocket.connect()
    }

    disconnect(): void {
        if (!this._chatSocket.connected) return
        this._chatSocket.disconnect()
    }


    emitVoice(voice: Blob): void {
        this._chatSocket.emit(SOCKET_EVENT.VOICE, voice)
    }

    emitChatMessage({ chatInfo, message, token, thread_id, messageId }: AskPayload): void {
        const authorization =  `Basic ${token}`
        const {id, ...rest} = chatInfo
        const data =  { chat_id: id, ...rest, message, rsp_message_id: messageId , thread_id, authorization}
        this._chatSocket.emit(SOCKET_EVENT.CHAT_MESSAGE, data)
    }

}
