import { PaletteColorOptions, PaletteOptions} from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette'

declare module '@mui/material/styles' {

  interface CustomPalette<T> {
    linearBlue: T
    linearYellow: T
    linearPurple: T
    linearOrange: T
  }
  export interface PaletteOptions extends CustomPalette<PaletteColorOptions>{
  }
  export interface Palette extends CustomPalette<PaletteColor>{
  }
}

export const palette: PaletteOptions = {
    linearBlue: {
      main: "linear-gradient(white, white), radial-gradient(circle at center, #0aa8c2 50%, #0e7be7)"
    },
    linearYellow: {
      main: "linear-gradient(white, white), radial-gradient(circle at center, #e94b47, #e0ac27)"
    },
    linearPurple: {
      main: "linear-gradient(white, white), radial-gradient(circle at center, #5f1084 45%, #f01bf4)"
    },
    linearOrange: {
      main: "linear-gradient(white, white), radial-gradient(circle at center, #e94b47 53%, #e7643f)"
    }
  };

