import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme, Color } from '@mui/material'

declare module '@mui/material/Button' {
  export interface ButtonPropsVariantOverrides {
    utterance: true;
    followUp: true;
  }
  interface ButtonPropsColorOverrides {
    linearBlue: true;
    linearYellow: true;
    linearPurple: true;
    linearOrange: true;
  }
}

type muiButton = {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
};

export const MuiButton: muiButton = {
  variants: [
    {
      props: { variant: 'utterance' },
      style: {
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
        border: 'double 1px transparent',
        width: '100%',
      }
    },
    {
      props: {color: 'linearBlue' },
      style: ({ theme }) => {
        return {
          backgroundImage: (theme as any).palette.linearBlue.main,
        }
      },
    },
    {
      props: {color: 'linearYellow' },
      style: ({ theme }) => {
        return {
          backgroundImage: (theme as any).palette.linearYellow.main,
        }
      },
    },
    {
      props: {color: 'linearPurple' },
      style: ({ theme }) => {
        return {
          backgroundImage: (theme as any).palette.linearPurple.main,
        }
      },
    },
    {
      props: {color: 'linearOrange' },
      style: ({ theme }) => {
        return {
          backgroundImage: (theme as any).palette.linearOrange.main,
        }
      },
    },
    {
      props: { variant: 'followUp' },
      style: {
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
        border: 'double 1px transparent',
        fontSize: '12px',
        textTransform: 'lowercase'
      }
    },
  ],
}
