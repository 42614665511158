import React from 'react'
import { Grid } from '@mui/material'
import UtteranceSection from '@molecules/UtteranceSection'
import { nanoid } from 'nanoid'
import { QuestionsProps } from '@molecules/Questions/types'

function Questions({ handleQuestion, utteranceSections }: QuestionsProps) {
  return (
    <Grid container spacing={2}>
      {
        utteranceSections.map(({ name, color, utterances }) => (
          <Grid item xs={6} key={nanoid()}>
            <UtteranceSection name={name} color={color}
                              utterances={utterances}
                              handleUtterance={handleQuestion} />
          </Grid>
        ))
      }
    </Grid>
  )
}

export default Questions
