import { keyframes } from '@mui/system'
import { styled } from '@mui/material'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

const bouncing = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`

const BouncingWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    width: '10px',
    height: '10px',
    margin: '3px 6px',
    borderRadius: '50%',
    backgroundColor: '#e94b47',
    opacity: 1,
    animation: `${bouncing} 0.6s infinite alternate`,
    '&:nth-of-type(2)': {
      animationDelay: '0.2s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '0.4s',
    },
  },
}))

const TypingIndicator = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <BouncingWrapper {...props}>
      <div></div>
      <div></div>
      <div></div>
    </BouncingWrapper>
  )
}

export default TypingIndicator
