import React, { useCallback } from 'react'
import { UtteranceSectionProps } from '@molecules/UtteranceSection/types'
import { Button, Card, CardActions, CardContent, CardHeader, Collapse } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandUtteranceButton from '@atoms/ExpandUtteranceButton'
import { nanoid } from 'nanoid'

function UtteranceSection({ name, color, utterances, handleUtterance }: UtteranceSectionProps) {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = useCallback(() => {
    setExpanded(prevState => !prevState)
  }, [])

  return (
    <Card variant='utterance'>
      <CardHeader title={name} className='header' />
      <CardContent>
        {
          utterances.principal.map((utterance) => (
            <Button key={nanoid()}
                    variant='utterance'
                    color={color as any}
                    onClick={() => handleUtterance(utterance)}>
              {utterance}
            </Button>
          ))
        }
      </CardContent>
      {
        utterances.hidden && utterances.hidden.length > 0 && (
          <>
            <CardActions disableSpacing>
              <ExpandUtteranceButton
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label='show more'>
                <ExpandMoreIcon />
              </ExpandUtteranceButton>
            </CardActions>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <CardContent>
                {utterances.hidden.map((utterance) => (
                  <Button key={nanoid()}
                          variant='utterance'
                          color={color as any}
                          onClick={() => handleUtterance(utterance)}>
                    {utterance}
                  </Button>
                ))}
              </CardContent>
            </Collapse>
          </>
        )
      }
    </Card>
  )
}

export default UtteranceSection
