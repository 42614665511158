// TODO: Move to backend and use in frontend
export const objects = [
    "bankTransactions", "suggestedTransactions", "invoices", "quotes",
    "salesReceipts", "expenses", "CreditNotes", "vendorBills", "cashflow"
];

export const statuses: {[key: string]: string[]} = {
    cashflow: [],
    bankTransactions: ["UNRECONCILED", "PARTIALLY_RECONCILED", "TO_REVIEW", "RECONCILED", "FORECASTED"],
    suggestedTransactions: ["INCOMING", "OUTGOING"],
    invoices: ["DRAFT", "DUE", "PAST_DUE", "PARTIALLY_PAID", "PAID"],
    quotes: ["DUE", "PAST_DUE", "WON"],
    salesReceipts: ["DUE", "PAST_DUE", "COLLECTED"],
    expenses: ["DUE", "PAST_DUE", "PAID"],
    CreditNotes: ["DRAFT", "DUE", "PAST_DUE", "PARTIALLY_REFUNDED", "REFUNDED"],
    vendorBills: ["DUE", "PAST_DUE", "PARTIALLY_PAID", "PAID"]
};

export const logicalOperators = ['and', 'or'];
export const comparisonOperators = ['>', '<', '=', '>=', '<=', '!='];