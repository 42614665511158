import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react'
import 'moment/locale/fr'
import moment from 'moment'
import { DEFAULT_DATE_FORMAT } from '@constants/i18n'
import type { ChatInfo, GlobalContextData, UserInfo } from './types'
import { useSessionStorage } from '@hooks/useSessionStorage'
import { nanoid } from 'nanoid'
import {socketManager} from "@config/socket.config";
import {Assistant, Object as OBJ} from "@services/rest/backend/types";
import {toast} from "react-toastify";
import {RestApiService} from "@services/rest/backend";

const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData)

function useGlobalProvider(): GlobalContextData {
  const [user, setUser] = useSessionStorage<UserInfo | null>('user', null)
  const [chat, setChat] = useSessionStorage<ChatInfo | null>('chat', {
    id: nanoid(),
    botId: 'businessAssistant',
    botName: 'Business Assistant',
  })

  const [objects, setObjects] = useState<OBJ[]>([])

  const restApiService = useMemo(() => new RestApiService(), [])

  // call this function when you want to authenticate the user
  const login = (data: UserInfo) => {
    setUser(data)
  }

  const newChat = (data: ChatInfo) => {
    console.log('newChat', chat)
    setChat(data)
  }

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null)
    setChat(null)
  }

  const getObject = () => {
    restApiService.getObject().then((objects) => {
      setObjects(objects)
    })
  }

  const updateObjects = (data: OBJ[]) => {
    if (!chat) return
    restApiService.updateObject(data)
        .then(() => setObjects(data))
        .then(() => toast.success('Objects updated'))
        .catch(() => toast.error('Error updating objects'))
  }

  useEffect(() => {
    if (!chat) return
    getObject()
  }, [restApiService])

  return {
    userInfo: user,
    chatInfo: chat,
    objects,
    newChat,
    login,
    logout,
    updateObjects,
  }
}

function GlobalProvider({ children }: PropsWithChildren) {
  moment.defaultFormat = DEFAULT_DATE_FORMAT
  //moment().locale(language)
  const values = useGlobalProvider()

  return <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
}

const useGlobalContext = (): GlobalContextData => {
  const globalContext = useContext<GlobalContextData>(GlobalContext)
  if (globalContext === undefined) {
    throw new Error('GlobalContext must be used within a Provider')
  }
  return globalContext
}
export { GlobalProvider, useGlobalContext, GlobalContext }
